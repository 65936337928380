import { Box, Flex, Grid, Heading, Hide, Image, Text } from '@chakra-ui/react';
import { Container } from 'components';
import React from 'react';

import loan from 'assets/images/loan.png';
import LoanBoardForm from './LoanBoardForm';

function LoanBoard() {
  return (
    <Container>
      <Box
        borderRadius="1rem"
        bgColor="red.500"
        p={['2.5rem 1rem', '2.5rem 1.5rem', '2.5rem']}
      >
        <Grid
          templateColumns={['1fr', '1fr', '1fr', '350px 1fr', '450px 1fr']}
          gap="1.5rem"
        >
          <Flex flexDir="column" justifyContent="space-between">
            <Box>
              <Heading
                color="white"
                fontSize={['1.35rem', '1.5rem', '1.75rem', '2.5rem']}
                textAlign={['center', 'left']}
              >
                Access loans to fund your personal needs!
              </Heading>
              <Text
                fontSize={['0.75rem', '0.875rem', '1rem', '1rem', '1.25rem']}
                lineHeight={[
                  '1.15rem',
                  '1.35rem',
                  '1.5rem',
                  '1.5rem',
                  '1.75rem',
                ]}
                color="white"
                textAlign={['center', 'left']}
                my="1rem"
              >
                With Snow Credit, you can access loans tailored to suit your
                needs. Whether it is a personal, educational, or a business
                need, we offer funding for your various needs with attractive
                interest rates.
              </Text>
            </Box>
            <Hide below="md">
              <Image src={loan} alt="happy people" w="full" height="auto" />
            </Hide>
          </Flex>
          <Flex alignItems="center">
            <Box
              w="full"
              p={['1rem 1rem', '1.5rem']}
              borderRadius="15px"
              bg="white"
            >
              <LoanBoardForm />
            </Box>
          </Flex>
        </Grid>
      </Box>
    </Container>
  );
}

export default LoanBoard;
