/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { ComponentStyleConfig } from '@chakra-ui/theme';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    color: 'white',
    fontWeight: 'medium',
    borderRadius: '15px',
    height: '50px',
  },
  variants: {
    primary: {
      backgroundColor: 'red.500',
      color: 'white',
      paddingX: '2rem',
      _hover: {
        backgroundColor: 'red.400',
      },
    },
    secondary: {
      backgroundColor: 'grey.500',
      color: 'white',
      paddingX: '2rem',
      _hover: {
        backgroundColor: 'grey.300',
      },
    },
    outline: {
      backgroundColor: 'transparent',
      color: 'grey.900',
      borderWidth: '1px',
      borderColor: 'grey.900',
      paddingX: '0.75rem',
      _hover: {
        backgroundColor: 'grey.900',
        color: 'white',
      },
    },
    link: {
      padding: 0,
      backgroundColor: 'transparent',
      _hover: {
        textDecoration: 'none',
      },
    },
    subtle: (props) => ({
      backgroundColor: `${props.colorScheme}.50`,
      color: `${props.colorScheme}.700`,
    }),
  },
};
