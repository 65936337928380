import { Box } from '@chakra-ui/react';
import { faqs } from 'constants/faq';
import { FaqWrapper } from 'features/faq';
import React from 'react';

function FaqContainer() {
  return (
    <Box id="faq">
      <FaqWrapper faqs={faqs.general} link />;
    </Box>
  );
}

export default FaqContainer;
