/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  NumberInput,
  NumberInputField,
  Select,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { formatCurrency } from 'utils/formatCurrency';
import { percentage } from 'utils/percentage';
import LoanDisplay from './loans/LoanDisplay';
import LoanSlider from './loans/LoanSlider';

function LoanBoardForm() {
  // const parse = (val: any) => val.replace(/^\$/, '');
  const [amount, setAmount] = useState<number>(300000);
  const [duration, setDuration] = useState<number>(2);

  const interest = useMemo(() => percentage(amount, 10), [amount]);

  const futureMonth = useMemo(
    () => moment().add(duration, 'M').format('DD MMM, YYYY'),
    [duration]
  );

  const payback = useMemo(() => amount + interest, [amount, interest]);

  return (
    <Box>
      <SimpleGrid columns={[1, 2]} spacing={['1.5rem', '3rem']}>
        <Box pt={6}>
          <Text mb="3rem" fontWeight="600">
            How much do you need?
          </Text>
          <LoanSlider
            value={amount}
            onChange={setAmount}
            min={10000}
            max={1000000}
            transform={amount > 900000 && 'translateX(-60%)'}
            isMoney
          />
          <NumberInput
            aria-label="amount input"
            mt="3rem"
            min={10000}
            max={1000000}
            onChange={(valueString) => setAmount(parseInt(valueString, 10))}
            value={formatCurrency(amount, { showCurrency: true })}
          >
            <NumberInputField />
          </NumberInput>
        </Box>
        <Box pt={[0, 6]} pb={2}>
          <Text mb="3rem" fontWeight="600">
            When do you want to pay back?
          </Text>
          <LoanSlider
            transform={duration > 3 && 'translateX(-60%)'}
            value={duration}
            onChange={setDuration}
            min={1}
            max={4}
          />

          <Select
            mt="3rem"
            value={duration}
            onChange={(e) => setDuration(parseInt(e?.currentTarget?.value, 10))}
          >
            <option value="1">1 Month</option>
            <option value="2">2 Months</option>
            <option value="3">3 Months</option>
            <option value="4">4 Months</option>
          </Select>
        </Box>
      </SimpleGrid>

      <Text fontWeight="600" mt="2.5rem">
        Here’s a breakdown of your loan
      </Text>

      <SimpleGrid mt="1rem" columns={[1, 2]} spacing="1rem">
        <LoanDisplay
          title="Principal"
          value={formatCurrency(amount, { showCurrency: true })}
        />
        <LoanDisplay
          title="Interest"
          value={formatCurrency(interest, { showCurrency: true })}
        />
        <LoanDisplay
          title="What you pay back"
          value={formatCurrency(payback, { showCurrency: true })}
        />
        <LoanDisplay title="Due Date" value={futureMonth} />
      </SimpleGrid>

      <Button
        as="a"
        target="_blank"
        href="https://app.snow.credit/login"
        colorScheme="red"
        color="white"
        w="full"
        mt="1rem"
        borderRadius="10px"
      >
        Get this offer
      </Button>
    </Box>
  );
}

export default LoanBoardForm;
