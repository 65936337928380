import { Box, Link, Text } from '@chakra-ui/react';
import React from 'react';

function Contact() {
  return (
    <Box id="contact">
      <Text
        fontSize="1.5rem"
        fontWeight="700"
        color="black"
        mt="2.5rem"
        mb="1.5rem"
      >
        Contact Us
      </Text>

      <Text>
        If you have any questions about this Agreement, please contact us by
        sending an email to{' '}
        <Link
          color="blue"
          textDecor="underline"
          href="mailto: support@snow.credit"
        >
          support@snow.credit{' '}
        </Link>
        or call +234 909 385 2301.
      </Text>
    </Box>
  );
}

export default Contact;
