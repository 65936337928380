import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

function LoanDisplay({ title, value }: any) {
  return (
    <Flex
      py="8px"
      bgColor="red.100"
      borderRadius="10px"
      border="1px solid"
      borderColor="red.500"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Text fontSize="0.875rem">{title}</Text>
      <Text fontSize="1.15rem" fontWeight="600">
        {value}
      </Text>
    </Flex>
  );
}

export default LoanDisplay;
