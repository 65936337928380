import { faqs } from 'constants/faq';
import React, { useMemo } from 'react';
import FaqWrapper from './FaqWrapper';

function MainFaq() {
  const [category, setCategory] = React.useState<
    'repaymentAndTransactions' | 'loans' | 'security'
  >('loans');

  const selectedFaqs = useMemo(() => faqs[category], [category]);

  return (
    <FaqWrapper
      faqs={selectedFaqs}
      category={category}
      setCategory={setCategory}
    />
  );
}

export default MainFaq;
