import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Text, Link } from '@chakra-ui/react';
import React from 'react';
import { LoanProps } from 'types/loan';

function LoanCard({
  title,
  description,
  amount,
  tenor,
  interest,
  link,
  color,
  bgColor,
}: LoanProps) {
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      borderRadius="15px"
      bg={bgColor}
      p={['1.5rem', '2rem 1.5rem', '2rem 1.5rem', '2rem 1.5rem', '2.5rem']}
    >
      <Box>
        <Heading
          fontSize={['1.5rem', '2rem', '2.25rem', '2.1rem', '2.5rem']}
          color={color}
        >
          {title}
        </Heading>
        <Text
          mt="1rem"
          color={color}
          fontSize={['1rem', '1.1rem', '1.1rem', '1.1rem', '1.2rem']}
        >
          {description}
        </Text>
      </Box>

      <Box>
        <Box
          mt="2.5rem"
          mb={['1rem', '1rem', '1rem', '1rem', '2.5rem']}
          bgColor="white"
          borderRadius="10px"
          p="1rem"
        >
          <Heading fontSize="1rem" color={color} mb="1rem">
            {title}
          </Heading>
          <Flex mb="1rem" justifyContent="space-between" alignItems="center">
            <Box>
              <Text fontSize="0.75rem" mb="0.35rem">
                Amount
              </Text>
              <Text fontWeight="600" fontSize="0.875rem">
                {amount}
              </Text>
            </Box>
            <Box>
              <Text fontSize="0.75rem" textAlign="right" mb="0.35rem">
                Tenor
              </Text>
              <Text textAlign="right" fontWeight="600" fontSize="0.875rem">
                {tenor}
              </Text>
            </Box>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <Box>
              <Text fontSize="0.75rem" mb="0.35rem">
                Interest
              </Text>
              <Text fontWeight="600" fontSize="0.875rem">
                {interest}
              </Text>
            </Box>
            <Box>
              <Link href={link} target="_blank">
                <Flex alignItems="center" gap="1.1rem">
                  <Text fontSize="1rem" color={color} fontWeight="bold">
                    Take Loan
                  </Text>
                  <ArrowForwardIcon w="1rem" h="1rem" color={color} />
                </Flex>
              </Link>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default LoanCard;
