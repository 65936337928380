import { Box, Link, Text } from '@chakra-ui/react';
import React from 'react';

function PrivacySidebar() {
  const [active, setActive] = React.useState('privacy');

  return (
    <Box h="full" w="350px" position="sticky" top="12.7rem">
      <Box onClick={() => setActive('privacy')} w="350px" mb="1.5rem">
        <Link _hover={{ textDecoration: 'none' }} href="/privacy#privacy">
          <Text
            _hover={{ color: 'red.500' }}
            fontSize="1.25rem"
            fontWeight="600"
            color={active === 'privacy' ? 'red.500' : 'black'}
          >
            Privacy Policy
          </Text>
        </Link>
      </Box>
      <Box onClick={() => setActive('terms')} mb="1.5rem">
        <Link _hover={{ textDecoration: 'none' }} href="/privacy#terms">
          <Text
            _hover={{ color: 'red.500' }}
            fontSize="1.25rem"
            fontWeight="600"
            color={active === 'terms' ? 'red.500' : 'black'}
          >
            Terms of Use
          </Text>
        </Link>
      </Box>
      <Box onClick={() => setActive('offers')} mb="1.5rem">
        <Link _hover={{ textDecoration: 'none' }} href="/privacy#offers">
          <Text
            _hover={{ color: 'red.500' }}
            fontSize="1.25rem"
            fontWeight="600"
            color={active === 'offers' ? 'red.500' : 'black'}
          >
            Offer Letter
          </Text>
        </Link>
      </Box>
      <Box onClick={() => setActive('license')} mb="1.5rem">
        <Link _hover={{ textDecoration: 'none' }} href="/privacy#license">
          <Text
            _hover={{ color: 'red.500' }}
            fontSize="1.25rem"
            fontWeight="600"
            color={active === 'license' ? 'red.500' : 'black'}
          >
            End User License Agreement
          </Text>
        </Link>
      </Box>
      <Box onClick={() => setActive('contact')} mb="1.5rem">
        <Link _hover={{ textDecoration: 'none' }} href="/privacy#contact">
          <Text
            _hover={{ color: 'red.500' }}
            fontSize="1.25rem"
            fontWeight="600"
            color={active === 'contact' ? 'red.500' : 'black'}
          >
            Contact Us
          </Text>
        </Link>
      </Box>
    </Box>
  );
}

export default PrivacySidebar;
