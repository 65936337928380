import {
  Box,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';

function PrivacyPolicy() {
  return (
    <Box id="privacy">
      <Text fontSize="1.5rem" fontWeight="700" color="black" mb="1.5rem">
        Privacy Policy
      </Text>

      <Text fontSize="1rem" color="black">
        This privacy policy (Policy) together with our Terms of Use and Loan
        Account Agreement as set out and any additional terms of use applies to
        your use of:
      </Text>

      <OrderedList my="1rem">
        <ListItem color="black" mb="0.75rem">
          Snow Credit mobile application software (App) available on our site OR
          hosted on the Google Play Store (App Site), once you have downloaded
          or streamed a copy of the App onto your mobile telephone or handheld
          device (Device).
        </ListItem>
        <ListItem color="black">
          Any of the services accessible through the App (Services) or those
          available on the App Site or other sites of ours or other third-party
          Sites (Service Sites).
        </ListItem>
      </OrderedList>

      <Text fontSize="1rem" color="black" mb="1rem">
        This policy sets out the basis on which any personal data we collect
        from you, or that you provide to us, will be processed by us.
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        Please read the following carefully to understand our views and
        practices regarding your personal data and how we will treat it. By
        downloading the App, you confirm that you have read, understood and
        accept the terms of this Policy set out hereunder. You also consent to
        the collection, use, storage, processing and disclosure of your personal
        information in the manner set out in this Policy.
      </Text>

      {/* info to collect from you */}

      <Text fontSize="1.1rem" fontWeight="700" color="black" mb="1rem">
        A. Information we may collect from you
      </Text>

      <Text fontSize="1rem" color="black">
        We may collect and process the following data about you.
      </Text>

      <OrderedList listStyleType="none" my="1rem">
        <ListItem color="black" mb="0.75rem" ml="-1rem">
          <Text fontWeight="700" fontSize="1rem" color="black">
            i. Information you give us about you (Submitted information):
          </Text>

          <Text fontSize="1rem" color="black" my="1rem">
            This may include information:
          </Text>

          <UnorderedList>
            <ListItem color="black" mb="0.75rem">
              Provided by filling in forms when visiting our sites or on our
              App;
            </ListItem>
            <ListItem color="black" mb="0.75rem">
              Provided by corresponding with us (for example, by e-mail or
              chat);
            </ListItem>
            <ListItem color="black" mb="0.75rem">
              {`Provided by downloading or signing up on the App, subscribing to
              any of our Services (such as applying for a loan), searching for
              an app or Service, sharing data via the App's social media
              functions, entering a competition, promotion or survey, and
              reporting a problem with the App, our Services, our App Site or
              any of Our Service Sites.`}
            </ListItem>
          </UnorderedList>
        </ListItem>
      </OrderedList>

      <Text fontSize="1rem" color="black" my="1rem">
        {`
                This information may include your name, address, e-mail address and
                phone number, the Device's phone number, age, username, password and
                other registration information, financial and credit information
                (including your account details, bank account details, and bank
                verification number, where applicable), personal description and
                photograph.
            `}
      </Text>

      <OrderedList listStyleType="none" my="1rem">
        <ListItem color="black" mb="0.75rem" ml="-1rem">
          <Text fontWeight="700" fontSize="1rem" color="black">
            ii. Information we collect about you and your device.
          </Text>

          <Text fontSize="1rem" fontWeight="700" color="black" my="1rem">
            Each time you visit one of our Sites or use one of our Apps we may
            automatically collect the following information:
          </Text>

          <UnorderedList>
            <ListItem color="black" mb="0.75rem">
              {`Technical information, including the type of mobile device you
              use, unique device identifiers (for example, your Device's IMEI or
              serial number), information about the SIM card used by the Device,
              mobile network information, your Device’s operating system, the
              type of browser you use, or your Device’s location and time zone
              setting (Device Information);`}
            </ListItem>
            <ListItem color="black" mb="0.75rem">
              Information stored on your Device, including contact lists, call
              logs, SMS logs, social media accounts, other financial
              applications, photos, videos or other digital content (Content
              Information);
            </ListItem>
            <ListItem color="black" mb="0.75rem">
              Data from your use of any other third-party application on the
              Device or the Service Sites; and details of your use of any of our
              Apps or your visits to any of Our Service Sites; including, but
              not limited to, traffic data, location data, weblogs and other
              communication data (Log Information).
            </ListItem>
          </UnorderedList>
        </ListItem>
      </OrderedList>

      <OrderedList listStyleType="none" my="1rem">
        <ListItem color="black" mb="0.75rem" ml="-1rem">
          <Text fontWeight="700" fontSize="1rem" color="black">
            iii. Location information.
          </Text>

          <Text fontSize="1rem" color="black" my="1rem">
            We also use GPS technology OR other location services to determine
            your current location. You can withdraw your consent to our
            collection, processing or use of this information at any time by
            logging out and uninstalling the App from your Device.
          </Text>
        </ListItem>
      </OrderedList>

      <OrderedList listStyleType="none" my="1rem">
        <ListItem color="black" mb="0.75rem" ml="-1rem">
          <Text fontWeight="700" fontSize="1rem" color="black">
            iv. Information we receive from other sources (Third Party
            Information).
          </Text>

          <Text fontSize="1rem" color="black" my="1rem">
            Due to the nature of the Services which we provide, we are required
            to work with a number of third parties (including credit reference
            agencies and mobile network providers) and we may receive
            information about you from them.
          </Text>
        </ListItem>
      </OrderedList>

      <OrderedList listStyleType="none" my="1rem">
        <ListItem color="black" mb="0.75rem" ml="-1rem">
          <Text fontWeight="700" fontSize="1rem" color="black">
            v. Unique application numbers.
          </Text>

          <Text fontSize="1rem" color="black" my="1rem">
            When you install or uninstall a Service containing a unique
            application number or when such a Service searches for automatic
            updates, that number and information about your installation, for
            example, the type of operating system, may be sent to us.
          </Text>
        </ListItem>
      </OrderedList>

      {/* B. Tracking and cookies */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" mb="1rem">
        B. Tracking and cookies
      </Text>

      <Text fontSize="1rem" color="black">
        We may use mobile tracking technologies and/or website cookies to
        distinguish you from other users of the App, App Site, or Service Sites.
        This helps us to provide you with a good experience when you use the App
        or browse any of the Service Sites and also allows us to improve the App
        and Our Service Sites.
      </Text>

      {/* C. Uses made of the information */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        C. Uses made of the information
      </Text>

      <Text fontSize="1rem" color="black">
        We may associate any category of information with any other category of
        information and will treat the combined information as personal data in
        accordance with this Policy for as long as it is combined.
      </Text>
      <Text fontSize="1rem" color="black" my="1rem">
        Information collected by us shall be used for the purpose of determining
        whether or not to provide a loan to the customer, the amount of such
        loan and the terms and conditions applicable to such loan.
      </Text>
      <Text fontSize="1rem" color="black">
        Save in compliance with an order of the Court, Arbitral Panel, Tribunal,
        Regulatory Directive or Order or any other legal or regulatory
        obligation, we do not disclose information about identifiable
        individuals to other parties, but we may provide them with anonymous
        aggregate information about our users (for example, we may inform them
        that 500 men aged under 30 have applied for a loan on any given day).
      </Text>

      {/* D. Disclosure of your information */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        D. Disclosure of your information
      </Text>

      <Text fontSize="1rem" color="black">
        We may disclose some or all of the data we collect from you when you
        download or use the App to credit reference bureaus.
      </Text>
      <Text fontSize="1rem" color="black" my="1rem">
        We may disclose your personal information to any member of our group,
        which means our subsidiaries, affiliates, our holding company and its
        subsidiaries.
      </Text>
      <Text fontSize="1rem" color="black" mb="1rem">
        We may disclose your personal information to third parties:
      </Text>

      <UnorderedList>
        <ListItem mb="0.75rem">
          In the event that we sell or buy any business or assets, in which case
          we may disclose your personal data to the prospective seller or buyer
          of such business or assets;
        </ListItem>
        <ListItem mb="0.75rem">
          If Snow Credit or substantially all of its assets are acquired by a
          third party, in which case personal data held by it about its
          customers will be one of the transferred assets;
        </ListItem>
        <ListItem mb="0.75rem">
          If we are under a duty to disclose or share your personal data in
          order to comply with any legal or regulatory obligation or request;
          and/or
        </ListItem>
        <ListItem mb="0.75rem">
          In order to: enforce our Terms and Conditions and other agreements or
          to investigate potential breaches; report defaulters to any credit
          bureau; or for the purpose of publishing statistics relating to the
          use of the App, in which case all information will be aggregated and
          made anonymous.
        </ListItem>
      </UnorderedList>

      {/* E. Where We store your personal data */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        E. Where We store your personal data
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        The data that we collect from you may be transferred to, and stored at,
        a destination outside your country of origin or residence (as
        applicable). It may also be processed by staff operating outside your
        country of origin or residence (as applicable), who work for us or for
        one of our suppliers. These staff members may be engaged in the
        fulfillment of your requests on the Service Sites. By submitting your
        personal data, you agree to the collection, transfer, storing or
        processing of your personal data in the manner set out above. We will
        take all steps reasonably necessary to ensure that your data is treated,
        stored and processed securely and in accordance with this privacy
        Policy.
      </Text>
      <Text fontSize="1rem" color="black" mb="1rem">
        Where we have given you (or where you have chosen) a password that
        enables you to access certain parts of Our Service Sites, you are
        responsible for keeping this password confidential. We ask you not to
        share a password with anyone. Unfortunately, the transmission of
        information via the internet is not completely secure. Although we will
        do our best to protect your personal data, we cannot guarantee the
        security of your data transmitted to Our Service Sites; any transmission
        is at your own risk. Once we have received your information, we will use
        strict procedures and security features to try to prevent unauthorised
        access.
      </Text>

      {/* F. Your rights */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        F. Your rights
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        We will use your data for the purposes of compiling statistics relating
        to our user base or loan portfolio and may disclose such information to
        any third party for such purposes, provided that such information will
        always be anonymous.
      </Text>
      <Text fontSize="1rem" color="black" mb="1rem">
        Should we wish to use your information for marketing purposes, we will
        inform you prior to such use. You shall be entitled to prevent such
        usage by informing us, within 10 days of being informed of the proposed
        use, that you do not wish to disclose such information. You can also
        exercise the right at any time by contacting us at{' '}
        <a
          style={{ color: 'blue', textDecoration: 'underline' }}
          href="mailto:support@snow.credit"
        >
          support@snow.credit
        </a>
      </Text>

      {/* G. Changes to privacy policy */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        G. Changes to privacy policy
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        Any changes we may make to this Policy in the future will be posted on
        this page and, where appropriate, notified to you when you next start
        the App or log onto one of the Service Sites. The new terms may be
        displayed on-screen and you may be required to read and accept them to
        continue your use of the App or the Services. In any event, by
        continuing to use the App or any Services after the posting of any
        changes, you confirm your continuing acceptance of this Policy together
        with such changes, and your consent to the terms set out therein.
      </Text>

      {/* Use of Data */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        Use of Data
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        The Digital Banking Application uses the collected data for various
        purposes:
      </Text>

      <UnorderedList>
        <ListItem mb="0.75rem">
          To comply with applicable laws, regulations, legal processes or
          government request including but not limited to Know Your Customer
          Principles, Customer Due Diligence, Anti-Monie Laundering and
          Anti-Terrorism Financing
        </ListItem>
        <ListItem mb="0.75rem">To provide and maintain the Service</ListItem>
        <ListItem mb="0.75rem">
          To notify you about changes to the Service
        </ListItem>
        <ListItem mb="0.75rem">
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </ListItem>
        <ListItem mb="0.75rem">To provide customer care and support</ListItem>
        <ListItem mb="0.75rem">
          To provide analysis or valuable information so that we can improve the
          Service
        </ListItem>
        <ListItem mb="0.75rem">To monitor the usage of the Service</ListItem>
        <ListItem mb="0.75rem">
          To use your Personal Data for operational, marketing and other
          promotional purposes.
        </ListItem>
        <ListItem mb="0.75rem">
          To detect, prevent and address technical issues
        </ListItem>
      </UnorderedList>

      {/* Transfer of Data */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        Transfer of Data
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        Your information, including Personal Data, may be transferred to and
        maintained on computers located outside of your town, city, local
        government area, state, country or other governmental jurisdiction where
        the data protection laws may differ from those from your jurisdiction.
        If you are located outside Nigeria and choose to provide information to
        us, please note that we transfer the data, including Personal Data, to
        Nigeria and process it there. Your consent to this Privacy Policy
        followed by your submission of such information represents your
        agreement to that transfer. We will take all steps reasonably necessary
        to ensure that your data is treated securely and in accordance with this
        Privacy Policy and no transfer in your Personal Data will take place in
        an organization or a country unless there are adequate controls in place
        including the security of your data and other personal information.
      </Text>
      <Text fontSize="1rem" color="black" mb="1rem">
        Disclosure of Data Legal Requirements
      </Text>
      <Text fontSize="1rem" color="black" mb="1rem">
        We may disclose your Personal Data in the good faith belief that such
        action is necessary to:
      </Text>

      <UnorderedList>
        <ListItem mb="0.75rem">Comply with a legal obligation</ListItem>
        <ListItem mb="0.75rem">
          Protect and defend our rights or property
        </ListItem>
        <ListItem mb="0.75rem">
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </ListItem>
        <ListItem mb="0.75rem">
          Protect the personal safety of users of the Service or the public
        </ListItem>
        <ListItem mb="0.75rem">Protect against legal liability</ListItem>
      </UnorderedList>

      {/* Security of Data */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        Security of Data
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        The security of your data is important to us but remember that no method
        of transmission over the Internet, or method of electronic storage is
        100% secure. While we strive to use Global best practice commercially
        acceptable means to protect your Personal Data, we cannot guarantee its
        absolute security.
      </Text>
      <Text fontSize="1rem" color="black" mb="1rem">
        However, it is important to note that your password is the key to your
        account. Please use unique numbers, letters and special characters, and
        do not share your password with anyone. If you do share your password
        with others, you will be responsible for all actions taken in the name
        of your account and the consequences. If you lose control of your
        password, you may lose substantial control over your personal
        information and other information submitted to us. You could also be
        subject to legally binding actions taken on your behalf. Therefore, if
        your password has been compromised for any reason or if you have grounds
        to believe that your password has been compromised, you should
        immediately contact us and change your password. You are reminded to log
        off from your account and close the browser when finished while using a
        shared computer.
      </Text>

      {/* Service Providers       */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        Service Providers
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        {`
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), to provide the Service on our behalf, to
          perform Service-related services or to assist us in analyzing how our
          Service is used. These third parties have access to your Personal Data
          only to perform these tasks on our behalf and are obligated not to
          disclose or use it for any other purpose.
          `}
      </Text>

      {/* Links to Other Sites       */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        Links to Other Sites
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        {`
          Our Service does not address anyone under the age of 18 ("Children").
          `}
      </Text>
      <Text fontSize="1rem" color="black" mb="1rem">
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your child or children have provided us with personal data,
        please contact us at{' '}
        <a
          style={{ color: 'blue', textDecoration: 'underline' }}
          href="mailto:support@snow.credit"
        >
          support@snow.credit
        </a>
        . If we become aware that we have collected personal data from children
        without verification of parental consent, we shall take steps to remove
        that information from our servers.
      </Text>

      {/* Your Data Privacy Rights */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        Your Data Privacy Rights
      </Text>

      <UnorderedList>
        <ListItem mb="0.75rem">
          Right to Rectification: Users can modify or change their name, email,
          password, and mobile login PIN via their profile.
        </ListItem>
        <ListItem mb="0.75rem">
          Right of Access, Erasure, and Restrict Processing: Users can request
          access or erasure of their personal information, as well as request
          restriction on further processing of their personal information by
          contacting us at{' '}
          <a
            style={{ color: 'blue', textDecoration: 'underline' }}
            href="mailto:support@snow.credit"
          >
            support@snow.credit
          </a>{' '}
          Please allow up to 30 days for requests to be processed. We reserve
          the right to charge a reasonable fee to process excessive or repeated
        </ListItem>
        <ListItem mb="0.75rem">
          Right to Withdraw Consent: Users can stop receiving our promotional
          emails by following the unsubscribe instructions included in every
          email we send, or by adjusting your Marketing Preferences in your
          profile. Users also have choices with respect to cookies, as described
          above. By modifying your browser preferences, you have the choice to
          accept all cookies, to be notified when a cookie is set, or to reject
          all cookies. If you choose to reject all cookies some parts of our
          Service may not work properly in your case.
        </ListItem>
        <ListItem mb="0.75rem">
          Right to lodge a complaint with a supervisory authority: Should you
          feel your data privacy rights are not being adequately protected by
          us, you have the right to lodge a formal complaint with the
          appropriate supervisory authority.
        </ListItem>
      </UnorderedList>

      {/* Changes to this Privacy */}
      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        Changes to this Privacy
      </Text>

      <Text fontSize="1rem" color="black" mb="1rem">
        {`
          Policy We may update our Privacy Policy from time to time. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy. You are advised to
          review this Privacy Policy periodically for any changes. Changes to this
          Privacy Policy are effective when they are posted on this page.
          
          `}
      </Text>
      <Text fontSize="1rem" color="black" mb="1rem">
        Contact us if you have questions regarding your data privacy rights or
        would like to submit a related data privacy right request, please email
        us at{' '}
        <a
          style={{ color: 'blue', textDecoration: 'underline' }}
          href="mailto:support@snow.credit"
        >
          support@snow.credit
        </a>
        . Please allow up to 30 days for requests to be processed.
      </Text>
      <Text fontSize="1rem" color="black" mb="1rem">
        Contact us by sending an e-mail to{' '}
        <a
          style={{ color: 'blue', textDecoration: 'underline' }}
          href="mailto:support@snow.credit"
        >
          support@snow.credit
        </a>{' '}
        if you have any questions about this Privacy Policy.
      </Text>
    </Box>
  );
}

export default PrivacyPolicy;
