import { Box, Link, ListItem, OrderedList, Text } from '@chakra-ui/react';
import React from 'react';

function Terms() {
  return (
    <Box id="terms">
      <Text
        fontSize="1.5rem"
        fontWeight="700"
        color="black"
        mb="1.5rem"
        mt="2.5rem"
      >
        Terms of Use
      </Text>

      <Text fontSize="1rem" color="black">
        This Agreement is a financial services and an end-user license agreement
        between you (“End-user” “Borrower” or “you”) and the relevant PebbleCred
        Financial Technologies Limited entity duly incorporated and licensed
        under the laws of your country (“PebbleCred”, “us” or “we”) for the
        mobile application software, the data supplied with the software and the
        associated Services (defined below) (“App”).
      </Text>

      <Text fontSize="1rem" color="black" my="1rem">
        This Agreement (together with our Privacy Policy) sets out the complete
        terms and conditions (the “Terms and Conditions”) which shall be
        applicable to the Account (as hereinafter defined) opened by you with
        Snow Credit.
      </Text>

      <Text fontSize="1rem" color="black">
        These Terms and Conditions and any amendments or variations thereto take
        effect on their date of publication.
      </Text>

      <Text fontSize="1.1rem" fontWeight="700" color="black" my="1rem">
        IT IS AGREED AS FOLLOWS:
      </Text>

      <Text fontSize="1.25rem" fontWeight="600" color="black" mb="1rem">
        1. DEFINITIONS AND INTERPRETATION
      </Text>

      <Text fontSize="1rem" fontWeight="600" color="black" mb="1rem">
        1.1 Definitions
      </Text>

      <Text fontSize="1rem" color="black" my="1rem">
        For the purposes of this Agreement and the preamble above, unless the
        context requires otherwise:
      </Text>

      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Acceptable Use Restrictions</b> has the meaning given to it in clause
        5;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Agreement</b> means this Agreement;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Account</b> means your loan and savings account with Snow Credit;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Business Day</b> means a day other than a Saturday, Sunday or
        national or public holiday in your country;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Credentials</b> means your personal credentials used to access the
        App and operate your Account;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Credit Reference Bureau</b> includes any financial institution or a
        credit reference bureau duly licensed under the laws and regulations of
        your country at every given time, to inter alia, collect and facilitate
        the sharing of customer credit information;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Electronic Monie</b> means a monetary value that is electronically
        stored in an instrument or device representing an equal amount of cash;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Electronic Payment Service</b> means the payment service delivered in
        electronic form by a licensed payment service provider in accordance
        with the laws and regulations of your Nigeria;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Electronic Payment System</b> means the facility operated by the
        Payment System Provider for the provision of the Electronic Payment
        Service;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Encumbrance</b> includes any mortgage or charge (whether legal or
        equitable), lien, option, security interest, restrictive covenant,
        pledge, assignment, title retention, trust arrangement or other
        restriction of any kind or other encumbrance securing or any right
        conferring a priority of payment in respect of any obligation of any
        person;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Equipment</b> includes any mortgage or charge (whether legal or
        equitable), lien, option, security interest, restrictive covenant,
        pledge, assignment, title retention, trust arrangement or other
        restriction of any kind or other encumbrance securing or any right
        conferring a priority of payment in respect of any obligation of any
        person;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Encumbrance</b> includes your mobile phone handset, SIM Card and/or
        other equipment which when used together enables you to access the
        Network;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Event of Default</b> has the meaning given to it in clause 11.1;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Force Majeure</b> means events, circumstances or causes beyond the
        reasonable control of Snow Credit making Snow Credit’s performance of
        its obligations inadvisable, commercially impracticable, illegal, or
        impossible, including but not limited to acts of God, war, strikes or
        labour disputes, embargoes or government orders, pandemics, epidemics;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Licence Restrictions</b> has the meaning given to it in clause 4;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Loan</b> means the principal amount of the loan made or to be made by
        Snow Credit to you under this Agreement from time to time through the
        App or (as the context requires) the principal amount outstanding for
        the time being of that loan;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Mobile Network Operator</b> means a mobile network operator in your
        country licensed in accordance with the relevant laws and regulations;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Network</b> means a mobile cellular network operated by a Mobile
        Network Operator;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Mobile Network Operator</b> means a mobile network operator in your
        country licensed in accordance with the relevant laws and regulations;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Payment System Provider</b> means a Mobile Network Operator, Deposit
        Banks, and other financial institutions that has been duly licensed by
        the laws and regulations of Nigeria to provide electronic payment
        services in Nigeria;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Privacy Policy</b> means the Nigerian Privacy Policy that sets out
        the basis on which any personal data we collect from you, or that you
        provide to us, will be processed by us;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Request</b> means a request or instruction received by Snow Credit
        from you or purportedly from you through the Network and the Electronic
        Payment System and upon which Snow Credit is authorised to act;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Privacy Policy</b> means the Nigerian Privacy Policy that sets out
        the basis on which any personal data we collect from you, or that you
        provide to us, will be processed by us;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Services</b> shall include any form of financial services or products
        that Snow Credit may offer you pursuant to this Agreement and which you
        may from time to time subscribe to; and “Service” shall be construed
        accordingly;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>SIM Card</b> means the subscriber identity module which when used
        with the appropriate mobile phone handset enables you to access the
        Network and, in some cases, to use the Value Storage Account (as defined
        below);
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>SMS</b> means a short message service consisting of a text message
        transmitted from your mobile phone to another;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>System</b> means Snow Credit’s electronic communications software
        enabling you to communicate with Snow Credit for purposes of the
        Services. The System and the Services will for the purpose of this
        Agreement be accessed through the Electronic Payment System;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Technology</b> has the meaning given to it in clause 3.1.4;
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Transaction Fees</b> includes any fees and charges payable for the
        use of the Services as published by Snow Credit on Snow Credit’s website
        or by such other means as Snow Credit shall in its sole discretion
        determine. Transaction Fees are subject to change at any time at Snow
        Credit’s sole discretion; and
      </Text>
      <Text fontSize="1rem" color="black" mb="0.75rem">
        <b>Value Storage Account</b> means your electronic Monie store of value,
        being the record maintained by a Payment System Provider from time to
        time held by you in the Electronic Payment System.
      </Text>

      <Text
        fontSize="1.1rem"
        fontWeight="600"
        color="black"
        mb="1rem"
        mt="2rem"
      >
        1.2 Interpretation
      </Text>

      <Text fontSize="1rem" color="black" mb="0.75rem">
        In addition to the definitions in clause 1.1, unless the context
        requires otherwise:
      </Text>

      <OrderedList ml="2rem">
        <ListItem>
          The singular shall include the plural and vice versa;
        </ListItem>
        <ListItem>
          A reference to any one gender, whether masculine, feminine or neuter,
          includes the other two.
        </ListItem>
        <ListItem>
          All the headings and sub-headings in this Agreement are for
          convenience only and are not to be taken into account for the purposes
          of interpretation of this Agreement.
        </ListItem>
        <ListItem>
          The recitals and schedules shall be deemed to form part of this
          Agreement.
        </ListItem>
      </OrderedList>

      <OrderedList start={2}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>ACCEPTANCE OF TERMS AND CONDITIONS</ListItem>
        </Text>

        <OrderedList>
          <ListItem>
            You must carefully read and understand the Terms and Conditions set
            out in this Agreement and as amended from time to time by Snow
            Credit (the Terms and Conditions) before downloading or streaming
            the App or opening an account with Snow Credit which will govern the
            use and operation of the App and the Account.
          </ListItem>
          <ListItem>
            After downloading the App, you will be deemed to have accepted the
            Terms and Conditions immediately upon your clicking the “Continue”
            button during your Loan application on Snow Credit’s System or any
            other button which signifies your acceptance of these Terms and
            Conditions and an intention to use the App. If you do not agree with
            the Terms and Conditions please do not click “Continue” in Snow
            Credit’s System. Please note that you will not be able to access the
            Services if you decline the Terms and Conditions. If you do not
            agree to the Terms and Conditions, we will not give you access to
            Snow Credit Services.
          </ListItem>
          <ListItem>
            By downloading the App and opening an Account with Snow Credit, you
            agree to comply with and be bound by the Terms and Conditions
            governing the operation of the Account and you affirm that the Terms
            and Conditions herein are without prejudice to any other right that
            Snow Credit may have with respect to the Account in law or
            otherwise.
          </ListItem>
          <ListItem>
            These Terms and Conditions may be amended or varied by Snow Credit
            from time to time and your continued use of the Services constitutes
            your agreement to be bound by the terms of any such amendment or
            variation. Snow Credit will take all reasonable measures to notify
            you of any changes.
          </ListItem>
          <ListItem>
            From time to time updates to the App may be issued. Depending on the
            update, you may not be able to use the Services until you have
            downloaded or streamed the latest version of the App and accepted
            any new terms and conditions.
          </ListItem>
          <ListItem>
            {`
              By using the App or any of the Services, you consent to us
              collecting and using technical information about the Equipment and
              related software, hardware and peripherals for Services that are
              internet-based or wireless to improve our products and to provide
              any Services to you. If you use these Services, you consent to us
              and our affiliates' and licensees' transmission, collection,
              retention, maintenance, processing and use of your data to determine
              our credit scoring services or to improve our Services and/or your
              experience while using the App.
            `}
          </ListItem>
        </OrderedList>
      </OrderedList>

      <Text fontSize="1rem" color="black" mt="1.5rem" mb="0.75rem">
        {`
            These terms and conditions ("Terms", "Agreement") is an agreement
            between Snow Credit, that is operated by Snow Credit Limited and you
            (the user). This Agreement sets forth the general terms and conditions
            of your use of any of its products or services (collectively, "Mobile
            Application" or "Services").
          `}
      </Text>

      <OrderedList start={3}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>GRANT AND SCOPE OF LICENCE</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="1rem">
            In consideration of you agreeing to abide by the terms of this
            Agreement, we grant you a non-transferable, non-exclusive licence to
            use the App on your Equipment, subject to these Terms and
            Conditions. We reserve all other rights. Except as expressly set out
            in this Agreement or as permitted by any local law, you agree:
            <OrderedList mt="1rem">
              <ListItem mb="0.75rem">
                Not to rent, lease, sub-license, loan, translate, merge, adapt,
                vary or modify the App;
              </ListItem>
              <ListItem mb="0.75rem">
                Not to make alterations to, or modifications of, the whole or
                any part of the App, or permit the App or any part of it to be
                combined with, or become incorporated in, any other programs;
              </ListItem>
              <ListItem mb="0.75rem">
                Not to disassemble, decompile, reverse-engineer or create
                derivative works based on the whole or any part of the App or
                attempt to do any such thing except to the extent that such
                actions cannot be prohibited because they are essential for the
                purpose of achieving inter-operability of the App with another
                software program, and provided that:
              </ListItem>

              <OrderedList>
                <ListItem mb="0.75rem">
                  The information obtained by you during such activities is not
                  disclosed or communicated without our prior written consent to
                  any third party, and is not used to create any software that
                  is substantially similar to the App,
                </ListItem>
                <ListItem mb="0.75rem">
                  You include our copyright notice on all entire and partial
                  copies you make of the App on any medium and you undertake not
                  to provide or otherwise make available the App in whole or in
                  part (including object and source code), in any form to any
                  person without prior written consent from us; and
                </ListItem>
              </OrderedList>

              <ListItem mb="1rem">
                To comply with all technology control or export laws and
                regulations in your country that apply to the technology used or
                supported by the App or any Service (the Technology).
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={4}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>LICENCE RESTRICTIONS</ListItem>
        </Text>

        <OrderedList>
          <ListItem fontSize="1.1rem" fontWeight="600" mb="1rem">
            You must not:
          </ListItem>

          <OrderedList>
            <ListItem mb="0.75rem">
              Use the App or any Service in any unlawful manner, for any
              unlawful purpose, or in any manner inconsistent with this
              Agreement, or act fraudulently or maliciously, for example, by
              hacking into or inserting malicious code, including viruses, or
              harmful data, into the App, any Service or any operating system;
            </ListItem>
            <ListItem mb="0.75rem">
              Infringe our intellectual property rights or those of any third
              party in relation to your use of the App or any Service, including
              the submission of any material (to the extent that such use is not
              licensed by this Agreement);
            </ListItem>
            <ListItem mb="0.75rem">
              Transmit any material that is defamatory, offensive or otherwise
              objectionable in relation to your use of the App or any Service;
            </ListItem>
            <ListItem mb="0.75rem">
              Use the App or any Service in a way that could damage, disable,
              overburden, impair or compromise our systems or security or
              interfere with other users; and
            </ListItem>
            <ListItem mb="0.75rem">
              Collect or harvest any information or data from any Service or our
              systems or attempt to decipher any transmissions to or from the
              servers running any Service.
            </ListItem>
          </OrderedList>
        </OrderedList>
      </OrderedList>

      <OrderedList start={5}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>ACCEPTABLE USE RESTRICTIONS</ListItem>
        </Text>

        <OrderedList>
          <ListItem fontSize="1.1rem" fontWeight="600" mb="1rem">
            Intellectual Property Rights
          </ListItem>

          <OrderedList>
            <ListItem mb="0.75rem">
              You acknowledge that all intellectual property rights in the App
              and the Technology anywhere in the world belong to us or our
              licensors, that rights in the App are licensed (not sold or
              assigned) to you, and that you have no rights in, or to, the App
              or the Technology other than the right to use each of them in
              accordance with the terms of this Agreement. You acknowledge that
              you have no right to have access to the App in source-code form.
            </ListItem>
          </OrderedList>

          <ListItem fontSize="1.1rem" fontWeight="600" mb="1rem">
            Use of The Services
          </ListItem>

          <OrderedList>
            <ListItem mb="0.75rem">
              The Services offered by Snow Credit can only be utilised by
              persons over the age of 18. Snow Credit reserves the right to
              verify the authenticity and status of your Bank Accounts and Bank
              Verification Numbers with the relevant Payment System Provider.
            </ListItem>
            <ListItem mb="0.75rem">
              Snow Credit’s acceptance of your application for an Account will
              be displayed on the App. You hereby acknowledge and accept that
              the acceptance by Snow Credit of your application for an Account
              does not create any contractual relationship between you and the
              Payment System Provider.
            </ListItem>
            <ListItem mb="0.75rem">
              Snow Credit reserves the right to decline your application for a
              Loan or to revoke the same at any stage at Snow Credit’s sole and
              absolute discretion and without assigning any reason or giving any
              notice thereto.
            </ListItem>
            <ListItem mb="0.75rem">
              Snow Credit reserves the right (in its sole and absolute
              discretion) to issue, decline to issue a Loan and/or vary the
              terms of any Loan depending on its assessment of the credit
              profile of each individual borrower from time to time. The terms
              of the Loan and the interest rate payable in relation to each loan
              application will be displayed on the App.
            </ListItem>
          </OrderedList>

          <ListItem fontSize="1.1rem" fontWeight="600" mb="1rem">
            Personal Information
          </ListItem>

          <OrderedList>
            <ListItem mb="0.75rem">
              You hereby agree and authorise Snow Credit to verify information
              provided by you to Snow Credit against the information held by the
              Payment System Providers pursuant to the agreement between you and
              the relevant Payment System Provider for the provision of its
              products and services and the Electronic Payment Service and any
              other information bank available to Snow Credit in Nigeria.
            </ListItem>
            <ListItem mb="0.75rem">
              The information that Snow Credit may verify against the
              information held by the Payment System Providers includes (without
              limitation): your phone number, name, date of birth,
              Identification Number (“ID”) or Passport Number and such other
              information that will enable Snow Credit to identify you and
              comply with the regulatory “Know Your Customer” requirements in
              your country (together the “Personal Information”).
            </ListItem>
            <ListItem mb="0.75rem">
              You hereby agree and authorise Snow Credit to verify information
              including, but not limited to, data relating to your phone
              (including, without limitation, your phone’s history) from your
              Equipment, from any SMS sent to you or by you, from any 3rd party
              applications, and such other information as Snow Credit shall
              require for purposes of providing you the Services (the “Relevant
              Information”).
            </ListItem>
            <ListItem mb="0.75rem">
              You hereby consent to Snow Credit verifying the Personal
              Information and the Relevant Information with the Payment System
              Providers and using the Personal Information and the Relevant
              Information to the extent necessary in Snow Credit’s opinion.
            </ListItem>
            <ListItem mb="0.75rem">
              You hereby agree and authorise Snow Credit to obtain and procure
              your Personal Information and Relevant Information from your
              respective Payment System Provider and you further agree and
              consent to the disclosure and provision of such Personal
              Information by the Payment System Provider and further to
              indemnify and hold Snow Credit and the Payment System Provider
              harmless with respect to any claims, losses, liabilities and
              expenses (including legal fees and expenses) that may arise as a
              result of the disclosure and reliance on such Personal Information
              and/or Relevant Information.
            </ListItem>
            <ListItem mb="0.75rem">
              You hereby agree and authorise Snow Credit to obtain and procure
              your Personal Information from the Credit Reference Bureaus and
              you further agree and consent to the disclosure and provision of
              such Personal Information by the Credit Reference Bureaus.
            </ListItem>
            <ListItem mb="0.75rem">
              Snow Credit reserves the right to request for further information
              from you pertaining to your application for an Account at any
              time. Failure to provide such information within the time required
              by Snow Credit may result in Snow Credit declining to accept your
              application for an Account or a Loan.
            </ListItem>
            <ListItem mb="0.75rem">
              Snow Credit reserves the right to supply consumer credit
              information and information about the Borrower to the Credit
              Reference Bureaus, and in this regard: you confirm that Snow
              Credit may transmit to the Credit Reference Bureaus data about the
              App, opening and termination of an Account by you.
            </ListItem>
            <ListItem mb="0.75rem">
              You acknowledge that information on non-compliance with the Terms
              and Conditions of this Agreement may be transferred to the Credit
              Reference Bureaus; and
            </ListItem>
            <ListItem mb="0.75rem">
              The Credit Reference Bureaus may provide Snow Credit with a credit
              profile and possibly credit scores on your creditworthiness.
            </ListItem>
          </OrderedList>
        </OrderedList>
      </OrderedList>

      <OrderedList start={6}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>REQUESTS MADE BY THE BORROWER</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            You hereby irrevocably authorise Snow Credit to act on all Requests
            received by Snow Credit from you (or purportedly from you) through
            the System or your Equipment and to hold you liable in respect
            thereof. Snow Credit may nevertheless refuse to carry out any
            Requests in its sole and absolute discretion.
          </ListItem>
          <ListItem mb="0.75rem">
            Subject to its discretion, Snow Credit reserves the right to reject
            any Request in relation to a Loan application from you even if you
            have previously been issued with a Loan by Snow Credit.
          </ListItem>
          <ListItem mb="0.75rem">
            Snow Credit shall be entitled to accept and to act upon any Request,
            even if that Request is otherwise for any reason incomplete or
            ambiguous if, in its absolute discretion, Snow Credit believes that
            it can correct the incomplete or ambiguous information in the
            Request without any reference to you being necessary.
          </ListItem>
          <ListItem mb="0.75rem">
            Snow Credit shall be deemed to have acted properly and to have fully
            performed all the obligations owed to you notwithstanding that the
            Request may have been initiated, sent or otherwise communicated in
            error or fraudulently, and you shall be bound by any Requests on
            which Snow Credit may act if Snow Credit has in good faith acted in
            the belief that such instructions have been sent by you.
          </ListItem>
          <ListItem mb="0.75rem">
            Snow Credit may, in its absolute discretion, decline to act on or in
            accordance with the whole or any part of your Request pending
            further enquiry or further confirmation (whether written or
            otherwise) from you.
          </ListItem>
          <ListItem mb="0.75rem">
            You agree to and shall release from and indemnify Snow Credit
            against all claims, losses, damages, costs and expenses howsoever
            arising in consequence of, or in any way related to Snow Credit
            having acted in accordance with the whole or any part of any of your
            Requests (or failed to exercise) the discretion conferred upon it.
          </ListItem>
          <ListItem mb="0.75rem">
            You acknowledge that to the full extent permitted by law, Snow
            Credit shall not be liable for any unauthorised drawing, transfer,
            remittance, disclosure, any activity or any incident on your account
            by the fact of the knowledge and/or use or manipulation of your
            Account Personal Identification Number, password, ID or any means
            whether or not occasioned by your negligence.
          </ListItem>
          <ListItem mb="0.75rem">
            Snow Credit is authorised to effect such orders in respect of your
            Account as may be required by any court order or competent authority
            or agency under the applicable laws in your country.
          </ListItem>
          <ListItem mb="0.75rem">
            In the event of any conflict between any terms of any Request
            received by Snow Credit from you and this Agreement, this Agreement
            shall prevail.
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={7}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>INTEREST AND FEES</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            The interest payable by you to Snow Credit in relation to any Loan
            shall be displayed by Snow Credit on the App. Snow Credit shall be
            entitled to set and charge Transaction Fees, in connection with your
            use of the Services and from time to time amend or vary its
            Transaction Fees for the Services. If Snow Credit decides to charge
            Transaction Fees or where already applicable, vary or amend its
            Transaction Fees, the Transaction Fees payable on any new
            application for Services will be displayed on the App. Snow Credit
            will use reasonable endeavours to notify you of any changes in
            relation to Transaction Fees within a reasonable period before such
            changes are implemented including displaying notices of the changes
            on the App or Snow Credit’s website.
          </ListItem>
          <ListItem mb="0.75rem">
            All payments to be made by you under this Agreement shall be made in
            full without any set off or counterclaim and save in so far as
            required by the law to the contrary, free and clear of and without
            any deduction or withholding whatsoever. If you are at any time
            required to make any deduction or withholding from any payment to
            Snow Credit, you shall immediately pay to Snow Credit such
            additional amounts as will result in Snow Credit receiving the full
            amount it would have received had no such deduction or withholding
            been required.
          </ListItem>
          <ListItem mb="0.75rem">
            If you fail to make any payments due to Snow Credit at the due date
            for payment, Snow Credit will be authorised to apply late fees on
            such amount loaned to you at a rate to be communicated to you.
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={8}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>STATEMENTS</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            A statement and activity report in respect of your account will be
            made available on request. Requests shall be made via our e-mail
            address:{' '}
            <Link
              color="blue"
              textDecor="underline"
              href="mailto: support@snow"
            >
              support@snow
            </Link>
            .credit or via a contact link on the App.
          </ListItem>
          <ListItem mb="0.75rem">
            The statement on the App shall provide details of the last 4 (four)
            transactions (or such other number of transactions as determined by
            Snow Credit) in your Account initiated from your Equipment.
          </ListItem>
          <ListItem mb="0.75rem">
            Your statement will show all amounts added or taken from your
            Account. You must check your statement carefully and notify Snow
            Credit as soon as possible, and no later than 48 hours after receipt
            of your statement, if it includes any transaction or other entry
            which appears to you to be wrong or not made in accordance with your
            instructions.
          </ListItem>
          <ListItem mb="0.75rem">
            Snow Credit reserves the right to rectify discrepancies, add and/or
            alter the entries in your statements, without prior notice to you.
          </ListItem>
          <ListItem mb="0.75rem">
            Save for a manifest error, a statement issued to you in respect of
            your Account shall be conclusive evidence of the transactions
            carried out on your Snow Credit for the period covered in the
            statement.
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={9}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>TAXES</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            All payments to be made by you in connection with these Terms and
            Conditions or any Loan provided to you by Snow Credit are calculated
            without regard to any taxes payable by you in your country. If any
            taxes are payable in connection with the payments, you must ensure
            that you pay such taxes and that you pay to Snow Credit such
            additional amounts as will result in Snow Credit receiving the full
            amount it would have received had no such taxes been applicable to
            the payments.
          </ListItem>
          <ListItem mb="0.75rem">
            You hereby consent and agree that Snow Credit may withhold amounts
            in your Account if any tax authority in your country requires Snow
            Credit to do so, or Snow Credit is otherwise required by law or
            pursuant to agreements with any tax authority to do so, or if Snow
            Credit needs to comply with internal policies or with any applicable
            order or sanction of a tax authority in your country. You will,
            however, be notified if such deductions are made.
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={10}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>BORROWER’S RESPONSIBILITIES</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            You shall at your own expense provide and maintain in safe and
            efficient operating order your Equipment necessary for the purpose
            of accessing the System and the Services.
          </ListItem>
          <ListItem mb="0.75rem">
            You shall be responsible for ensuring the proper performance of your
            Equipment. Snow Credit shall neither be responsible for any errors
            or failures caused by any malfunction of your Equipment, nor shall
            Snow Credit be responsible for any computer virus or related
            problems that may be associated with the use of the System, the
            Services and the Equipment. You shall be responsible for charges due
            to any service provider providing you with connection to the Network
            and Snow Credit shall not be responsible for losses or delays caused
            by any such service provider.
          </ListItem>
          <ListItem mb="0.75rem">
            You shall follow all instructions, procedures and terms contained in
            this Agreement and any document provided by Snow Credit concerning
            the use of the System and the Services.
          </ListItem>
          <ListItem mb="0.75rem">
            You hereby agree and acknowledge that you shall be solely
            responsible for the safekeeping and proper use of your Equipment and
            for keeping your Credentials secret and secure. You shall ensure
            that your Credentials do not become known or come into possession of
            any unauthorised person. Snow Credit shall not be liable for any
            disclosure of your Credentials to any third party and you hereby
            agree to indemnify and hold Snow Credit harmless from any losses
            resulting from any disclosure of your Credentials.
          </ListItem>
          <ListItem mb="0.75rem">
            You shall take all reasonable precautions to detect any unauthorised
            use of the System and the Services. To that end, you shall ensure
            that all communications from Snow Credit are examined and checked by
            you or on your behalf as soon as practicable after receipt by you in
            such a way that any unauthorised use of and access to the System
            will be detected. You shall immediately inform Snow Credit in the
            event that:
            <OrderedList>
              <ListItem mt="1rem" mb="0.75rem">
                You have reason to believe that your Credentials are or may be
                known to any person not authorised to know the same and/or have
                been compromised; and/or
              </ListItem>
              <ListItem mb="0.75rem">
                You have reason to believe that unauthorised use of the Services
                has or may have occurred or could occur and a transaction may
                have been fraudulently initiated or compromised.
              </ListItem>
            </OrderedList>
            <ListItem mb="0.75rem">
              You shall at all times follow the security procedures notified to
              you by Snow Credit from time to time or such other procedures as
              may be applicable to the Services from time to time. You
              acknowledge that any failure on your part to follow the
              recommended security procedures may result in a breach of your
              Account’s confidentiality. In particular, you shall ensure that
              the Services are not used or Requests are not issued or the
              relevant functions are not performed by anyone other than a person
              authorised to do so.
            </ListItem>
            <ListItem mb="0.75rem">
              You shall not at any time operate or use the Services in any
              manner that may be prejudicial to Snow Credit.
            </ListItem>
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={11}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>DEFAULT ON LOAN</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            An event of default (Event of Default) occurs when:
            <OrderedList>
              <ListItem mt="1rem" mb="0.75rem">
                You fail to pay any sum payable under a Loan in accordance with
                a scheduled repayment.
              </ListItem>
              <ListItem mb="0.75rem">
                Any representation/information, warranty or assurance made or
                given by the Borrower in connection with the application for a
                Loan or any information or documentation supplied by the
                Borrower is incorrect, inaccurate or misleading;
              </ListItem>
              <ListItem mb="0.75rem">
                The Borrower does anything which may prejudice Snow Credit’s
                rights under this Agreement;
              </ListItem>
              <ListItem mb="0.75rem">
                You use or attempt to use the App or the Services for an
                unauthorised purpose; or
              </ListItem>
              <ListItem mb="0.75rem">
                The Borrower is declared bankrupt, insolvent or unable to pay
                his debts as they fall due under the laws of his country.
              </ListItem>
            </OrderedList>
          </ListItem>
          <ListItem mb="0.75rem">
            At any time after an Event of Default has occurred which is
            continuing, Snow Credit may, without prejudice to any other right or
            remedy granted to it under any law:
            <OrderedList>
              <ListItem mt="1rem" mb="0.75rem">
                Terminate this Agreement in accordance with clause 12 below;
              </ListItem>
              <ListItem mb="0.75rem">
                Declare that the Loan (and all accrued interest and all other
                amounts outstanding under this Agreement is immediately due and
                payable, whereupon they shall become immediately due and
                payable; and
              </ListItem>
              <ListItem mb="0.75rem">
                Supply information concerning the Event of Default to Credit
                Reference Bureaus. A copy of any adverse information concerning
                you sent to a Credit Reference Bureau shall be made available to
                you upon written request.
              </ListItem>
            </OrderedList>
            <ListItem mb="0.75rem">
              Snow Credit reserves the right to assign its right, title and
              interest under the Agreement to an external collections agency who
              will take all reasonable steps to collect the outstanding Loan
              (together with any interest and fees accrued), and in this regard,
              Snow Credit shall have the right to share with such collection
              agencies, any information about the Borrower which it deems
              relevant.
            </ListItem>
            <ListItem mb="0.75rem">
              Snow Credit also reserves the right to institute legal proceedings
              for the recovery of the Loan against you without any prior notice.
            </ListItem>
            <ListItem mb="0.75rem">
              The Borrower shall be responsible for all legal costs and expenses
              incurred by Snow Credit in connection with any enforcement action
              taken by Snow Credit for the collection and repayment of the Loan
              (together with any interest or fees due on the Loan).
            </ListItem>
            <ListItem mb="0.75rem">
              In the event that an Event of Default occurs, the Borrower grants
              to Snow Credit the right to notify the Borrower and any other
              person who, in Snow Credit’s opinion, may assist with the recovery
              of the outstanding loan amount. You agree that this notification
              may be done by any means of communication which Snow Credit deems
              appropriate.
            </ListItem>
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={12}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>VARIATION AND TERMINATION</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            Snow Credit may at any time, upon notice to you, terminate or vary
            its business relationship with you and close your Account and in
            particular but without prejudice to the generality of the foregoing,
            Snow Credit may cancel credits which it has granted and require the
            repayment of outstanding debts resulting from such credits within
            such time as Snow Credit may determine.
          </ListItem>
          <ListItem mb="0.75rem">
            Without prejudice to Snow Credit’s rights under clause 12.1, Snow
            Credit may at its sole discretion suspend or close your Account:
            <OrderedList>
              <ListItem mt="1rem" mb="0.75rem">
                You use the Account for unauthorised purposes or where Snow
                Credit detects any abuse/misuse, breach of content, fraud or
                attempted fraud relating to your use of the Services;
              </ListItem>
              <ListItem mb="0.75rem">
                If your Account or agreement with a Mobile Network Operator is
                terminated for whatever reason;
              </ListItem>
              <ListItem mb="0.75rem">
                If Snow Credit is required or requested to comply with an order
                or instruction of or a recommendation from the government,
                court, regulator or other competent authority;
              </ListItem>
              <ListItem mb="0.75rem">
                If Snow Credit reasonably suspects or believes that you are in
                breach of these Terms and Conditions (including non-payment of
                any Loan amount due from you where applicable) which you fail to
                remedy (if remediable) within 14 days after the service of
                notice by email, SMS or other electronic means requiring you to
                do so;
              </ListItem>
              <ListItem mb="0.75rem">
                Where such a suspension or variation is necessary as a
                consequence of technical problems or for reasons of safety; to
                facilitate update or upgrade the contents or functionality of
                the Services from time to time; or where your Account becomes
                inactive or dormant;
              </ListItem>
              <ListItem mb="0.75rem">
                If Snow Credit decides to suspend or cease the provision of the
                Services for commercial reasons or for any other reason as it
                may determine in its absolute discretion; or
              </ListItem>
              <ListItem mb="0.75rem">
                If you breach any of the Licence Restrictions or the Acceptable
                Use Restrictions.
              </ListItem>
            </OrderedList>
            <ListItem mb="0.75rem">
              If your Account has a credit balance at any time as a result of
              overpayment of your Loan, you may issue a Request to Snow Credit
              for payment of such credit balance and Snow Credit will return any
              such balance to you, less any applicable fees, provided that such
              amount falls above the minimum transfer amounts specified by the
              relevant Payment System Provider.
            </ListItem>
            <ListItem mb="0.75rem">
              Termination shall however not affect any accrued rights and
              liabilities of either party.
            </ListItem>
            <ListItem mb="0.75rem">
              If Snow Credit receives notice of your demise, Snow Credit will
              not be obliged to allow any operation or withdrawal from your
              Account by any person except upon production of administration
              letters from a competent authority or confirmed grant of letters
              of administration or confirmed grant of probate by your legal
              representatives duly appointed by a court of competent
              jurisdiction.
            </ListItem>
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={13}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>EXCLUSION OF LIABILITY</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            Snow Credit shall not be responsible for any loss suffered by you
            should the Services be interfered with or be unavailable by reason
            of the failure of any of your Equipment, or any other circumstances
            whatsoever not within Snow Credit’s control including , without
            limitation, Force Majeure or error, interruption, delay or non-
            availability of the System, terrorist or any enemy action, loss of
            power, adverse weather or atmospheric conditions, and failure of any
            public or private telecommunications system.
          </ListItem>
          <ListItem mb="0.75rem">
            You acknowledge that the App has not been developed to meet your
            individual requirements, and that it is therefore your
            responsibility to ensure that the facilities and functions of the
            App as described meet your requirements.
            <ListItem mb="0.75rem">
              We only provide the Services on the App for domestic, private use,
              and small and medium business financing needs, and we have no
              liability to you for any loss of profit, loss of business,
              business interruption, or loss of business opportunity as a result
              of your unauthorised use of the App.
            </ListItem>
            <ListItem mb="0.75rem">
              Snow Credit will not be liable for any losses or damage suffered
              by you as a result of or in connection with:
              <OrderedList>
                <ListItem mt="1rem" mb="0.75rem">
                  Any defect or fault in the App or any Service resulting from
                  you having altered or modified the App;
                </ListItem>
                <ListItem mb="0.75rem">
                  The App in breach of the terms of this Agreement;
                </ListItem>
                <ListItem mb="0.75rem">
                  Your breach of any of the Licence Restrictions or the
                  Acceptable Use Restrictions;
                </ListItem>
                <ListItem mb="0.75rem">
                  Unavailability of sufficient funds in your Value Storage
                  Account;
                </ListItem>
                <ListItem mb="0.75rem">
                  Failure, malfunction, interruption or unavailability of the
                  System, your Equipment, the Network or the Electronic Payment
                  System; the Monie in your Account being subject to legal
                  process or other encumbrance restricting payments or transfers
                  thereof; your failure to give proper or complete instructions
                  for payments or transfers relating to your Account
                </ListItem>
                <ListItem mb="0.75rem">
                  Any fraudulent or illegal use of the Services, the System
                  and/or your Equipment; or
                </ListItem>
                <ListItem mb="0.75rem">
                  Your failure to comply with the Terms and Conditions and any
                  document or information provided by Snow Credit concerning the
                  use of the System and the Services.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem mb="0.75rem">
              If for any reason other than a reason mentioned in clauses 13.1 to
              13.4, the Services are interfered with or unavailable, Snow
              Credit’s sole liability under this Agreement in respect thereof
              shall be to re-establish the Services as soon as reasonably
              practicable.
            </ListItem>
            <ListItem mb="0.75rem">
              Save as provided in clause 13.5, Snow Credit shall not be liable
              to you for any interference with or unavailability of the
              Services, howsoever caused.
            </ListItem>
            <ListItem mb="0.75rem">
              Under no circumstances shall Snow Credit be liable to you for any
              loss of profit or anticipated savings or for any indirect or
              consequential loss or damage of whatever kind, howsoever caused,
              arising out of or in connection with the Services even where the
              possibility of such loss or damage is notified to Snow Credit.
            </ListItem>
            <ListItem mb="0.75rem">
              All warranties and obligations implied by law are hereby excluded
              to the fullest extent permitted by law.
            </ListItem>
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={14}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>INDEMNITY</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            In consideration of Snow Credit complying with your instructions or
            requests in relation to your Account, you undertake to indemnify
            Snow Credit and hold it harmless against any loss, charge, damage,
            expense, fee or claim which Snow Credit suffers or incurs or
            sustains thereby and you absolve Snow Credit from all liability for
            loss or damage which you may sustain from Snow Credit acting on your
            instructions or Requests or in accordance with these Terms and
            Conditions.
          </ListItem>
          <ListItem mb="0.75rem">
            The indemnity in clause 14.1 shall also cover the following:
            <OrderedList>
              <ListItem mt="1rem" mb="0.75rem">
                All demands, claims, actions, losses and damages of whatever
                nature which may be brought against Snow Credit or which it may
                suffer or incur arising from its acting or not acting on any
                Request or arising from the malfunction or failure or
                unavailability of any hardware, software, or equipment, the loss
                or destruction of any data, power failures, corruption of
                storage media, natural phenomena, riots, acts of vandalism,
                sabotage, terrorism, any other event beyond Snow Credit’s
                control, interruption or distortion of communication links or
                arising from reliance on any person or any incorrect, illegible,
                incomplete or inaccurate information or data contained in any
                Request received by Snow Credit.
              </ListItem>
              <ListItem mb="0.75rem">
                Any loss or damage that may arise from your use, misuse, abuse
                or possession of any third-party software, including without
                limitation, any operating system, browser software or any other
                software packages or programs.
              </ListItem>
              <ListItem mb="0.75rem">
                Any unauthorised access to your Account or any breach of
                security or any destruction or accessing of your data or any
                destruction or theft of or damage to any of your Equipment.
              </ListItem>
              <ListItem mb="0.75rem">
                Any loss or damage occasioned by the failure by you to adhere to
                these Terms and Conditions and/or by supplying of incorrect
                information or loss or damage occasioned by the failure or
                unavailability of third party facilities or systems or the
                inability of a third party to process a transaction or any loss
                which may be incurred by Snow Credit as a consequence of any
                breach of these Terms and Conditions.
              </ListItem>
              <ListItem mb="0.75rem">
                Any damages and costs payable to Snow Credit in respect of any
                claims against Snow Credit for recompense for loss where the
                particular circumstance is within your control.
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={15}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>COMMUNICATION BETWEEN US</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            If the Borrower wishes to contact Snow Credit in writing, or if any
            condition in these Terms and Conditions requires the Borrower to
            give notice to Snow Credit, you can send this to us by e-mail to{' '}
            <Link
              color="blue"
              textDecor="underline"
              href="mailto: support@snow"
            >
              support@snow
            </Link>{' '}
            or to such e-mail address that may be communicated to you from time
            to time. We will confirm receipt of this by contacting you in
            writing by email.
          </ListItem>
          <ListItem mb="0.75rem">
            If we have to contact you or give you notice in writing, we will do
            so by posting such notice on our website; or by e-mail or sms to the
            mobile phone number or e-mail address you provide to us in your
            request for the App.
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={16}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>GENERAL</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            <b>Remedies Cumulative</b>
            <Text mt="0.75rem">
              No failure on the part of any party to exercise, or delay on its
              part in exercising, any right, power or remedy provided by this
              Agreement or by law shall operate as a waiver thereof, nor shall
              any single or partial exercise of any such right, power or remedy
              preclude any further or other exercise of that, or any other,
              right, power or remedy.
            </Text>
          </ListItem>
          <ListItem mb="0.75rem">
            <b>No waiver</b>
            <Text mt="0.75rem">
              No failure by Snow Credit to exercise, and no delay in exercising,
              any right or remedy in respect of any provision of this Agreement
              shall operate as a waiver of such right or remedy.
            </Text>
          </ListItem>
          <ListItem mb="0.75rem">
            <b>Effect of invalidity</b>
            <Text mt="0.75rem">
              If any provision or part of a provision of this Agreement shall
              be, or be found by any court of competent jurisdiction to be,
              invalid or unenforceable, such invalidity or unenforceability
              shall not affect the other provisions or parts of such provisions
              of this Agreement, all of which shall remain in full force and
              effect.
            </Text>
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={17}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>ENTIRE AGREEMENT</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            <b>Remedies Cumulative</b>
            <Text mt="0.75rem">
              No failure on the part of any party to exercise, or delay on its
              part in exercising, any right, power or remedy provided by this
              Agreement or by law shall operate as a waiver thereof, nor shall
              any single or partial exercise of any such right, power or remedy
              preclude any further or other exercise of that, or any other,
              right, power or remedy.
            </Text>
          </ListItem>
          <ListItem mb="0.75rem">
            These Terms and Conditions and our Privacy Policy constitute the
            entire agreement between you and us and supersede and extinguish all
            previous agreements, promises, assurances, warranties,
            representations and understandings between us, whether written or
            oral, relating to its subject matter.
          </ListItem>
          <ListItem mb="0.75rem">
            You acknowledge that in entering into this Agreement you do not rely
            on any statement, representation, assurance or warranty (whether
            made innocently or negligently) that is not set out in these Terms
            and Conditions or our Privacy Policy.
          </ListItem>
          <ListItem mb="0.75rem">
            You agree with us that neither of us shall have any claim for
            innocent or negligent misrepresentation or negligent misstatement
            based on any statement in this Agreement.
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={18}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>DISPUTE RESOLUTION</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            <b>Disputes</b>
            <Text mt="0.75rem">
              The Parties shall use their good faith efforts to resolve any
              dispute, controversy or claim of any nature whatsoever arising out
              of or in relation to or in connection with this Agreement. To this
              end, the Parties in dispute shall each promptly appoint
              representatives of appropriate standing who shall meet and attempt
              to resolve any dispute between them. In the event that an amicable
              settlement has not been reached within thirty (30) days of the
              parties’ representatives meeting as aforesaid, the following
              provisions of this clause 18 shall apply.
            </Text>
          </ListItem>
          <ListItem mb="0.75rem">
            <b>Arbitration</b>

            <OrderedList>
              <ListItem my="0.75rem">
                Any dispute, difference or question whatsoever and howsoever
                arising out of or in connection with this Agreement, save as
                specifically provided herein, shall be referred for final
                determination to a single arbitrator to be appointed by
                agreement between the parties hereto or in default of any such
                agreement within seven (7) days of the notification of any
                dispute by either party to the other then, upon application by
                either party, by the Chairman for the time being of the
                Institute of Arbitrators in your country (the Institute).
              </ListItem>
              <ListItem mb="0.75rem">
                Such arbitration shall take place in your country and shall be
                conducted in accordance with the applicable rules of arbitration
                of the Institute.
              </ListItem>
              <ListItem mb="0.75rem">
                To the extent permissible by law the determination of the
                arbitrator shall be final and binding upon the Parties and shall
                not be subject to any appeal.
              </ListItem>
              <ListItem mb="0.75rem">
                Nothing in this clause 18.2 shall restrict either Party’s
                freedom to commence legal proceedings of any nature for the
                purposes of seeking preliminary injunctive relief or interim or
                conservatory measures from any court of competent jurisdiction
                pending the final decision or award of any arbitrator.
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={19}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>GOVERNING LAW</ListItem>
        </Text>

        <Text>
          This Agreement shall be governed by and construed in accordance with
          the laws of your country.
        </Text>
      </OrderedList>

      <OrderedList start={20}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>Snow Credit’S PRIVACY POLICY</ListItem>
        </Text>

        <OrderedList>
          <ListItem mb="0.75rem">
            We only use your personal information in accordance with our Privacy
            Policy. Please take the time to read our Privacy Policy, as it
            includes important terms which apply to you.
          </ListItem>
          <ListItem mb="0.75rem">
            Upon downloading the App and clicking the “Accept” or “Continue” or
            any other button which signifies your acceptance of these Terms and
            Conditions and an intention to use the App, you will be deemed to
            have accepted Snow Credit’s Privacy Policy, a copy of which is
            available on the App.
          </ListItem>
        </OrderedList>
      </OrderedList>

      <OrderedList start={21}>
        <Text
          fontSize="1.25rem"
          fontWeight="600"
          color="black"
          mt="2rem"
          mb="1rem"
        >
          <ListItem>NIGERIA SPECIFIC PROVISIONS</ListItem>
        </Text>

        <Text>
          These additional terms and conditions shall apply to borrowers or
          users of the App and Services who are resident in Nigeria (“Nigerian
          Borrower”).
        </Text>

        <OrderedList>
          <ListItem my="0.75rem">
            <b>Snow Credit Limited Nigeria Entity</b>
            <Text mt="0.75rem">
              The relevant associated Snow Credit entity in Nigeria is Snow
              Credit Limited, an entity duly incorporated as a private limited
              liability company under the Companies and Allied Matters Act Cap
              C20, LFN 2004. Snow Credit is duly authorised to provide short
              term unsecured loans to individuals via a mobile lending
              application.
            </Text>
          </ListItem>
          <ListItem mb="0.75rem">
            <b>Governing Law</b>
            <Text mt="0.75rem">
              The Terms and Conditions between Snow Credit Limited and a
              Nigerian Borrower shall be governed by and construed in accordance
              with Nigerian Law.
            </Text>
          </ListItem>
          <ListItem mb="0.75rem">
            <b>Additional Dispute Resolution Provisions</b>
            <Text mt="0.75rem">
              Notwithstanding any provision to the contrary in this Agreement,
              Snow Credit Limited Nigeria shall have the right to institute
              proceedings for recovery of any outstanding amounts against a
              defaulting Nigerian Borrower in the High Court of Lagos State.
            </Text>
          </ListItem>
        </OrderedList>
      </OrderedList>
    </Box>
  );
}

export default Terms;
