import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import AppStoreLinks from './AppStoreLinks';

function AppStores() {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      gap="2.5rem"
      maxW="557px"
      mx="auto"
      mt="10rem"
    >
      <Text
        fontSize={['1.25rem', '1.5rem', '1.875rem']}
        fontWeight="600"
        color="black"
        textAlign="center"
      >
        Get started and download from the Google Play and App store
      </Text>
      <AppStoreLinks />
    </Flex>
  );
}

export default AppStores;
