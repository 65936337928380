import { Flex, Hide, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { AppStoreLinks, Container } from 'components';
import SectionTitle from 'components/SectionTitle';
import { Hero1, Hero2 } from 'assets/images';

function Hero() {
  return (
    <Container>
      <Flex
        position="relative"
        w="full"
        h={['55vh', '70vh']}
        maxH="640px"
        justifyContent="center"
        alignItems="center"
      >
        <Hide below="lg">
          <Image
            src={Hero1}
            alt="hero image"
            position="absolute"
            loading="lazy"
            w={['460px', '420px', '440px', '410px', '450px']}
            left={['-50%', '-50%', '-50%', '-30%', '-25%']}
            top="-5%"
          />
          <Image
            src={Hero2}
            alt="hero image"
            position="absolute"
            loading="lazy"
            w={['620px', '500px', '550px', '550px', '600px']}
            right={['-75%', '-80%', '-57%', '-35%', '-30%']}
            top="-5%"
          />
        </Hide>
        <Flex direction="column" alignItems="center" maxW="650px">
          <SectionTitle
            maxW="645px"
            title="Fund your dreams"
            subTitle="with Snow Credit!"
            isMb={false}
          />
          <Text
            fontSize={['1rem', '1.25rem', '1.25rem', '1.5rem', '1.5rem']}
            textAlign="center"
            color="grey.300"
            lineHeight={['1.5rem', '1.75rem', '1.75rem', '2rem', '2rem']}
            mt={['1rem', '1.5rem']}
            mb="3rem"
          >
            Get instant funding for your business and lifestyle, so you can
            focus on your dreams.
          </Text>
          <AppStoreLinks />
        </Flex>
      </Flex>
    </Container>
  );
}

export default Hero;
