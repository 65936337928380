import { Flex, Image, Link } from '@chakra-ui/react';
import { Apple, Google, WebApp } from 'assets/images';
import React from 'react';
import { AppStore } from 'types';

const appStores: AppStore[] = [
  {
    id: 1,
    link: 'https://play.google.com/store/apps/details?id=com.snowmfb',
    image: Google,
    alt: 'google store icon',
  },
  {
    id: 2,
    link: 'https://apps.apple.com/ng/app/snowcredit/id1626043225',
    image: Apple,
    alt: 'apple store icon',
  },
  {
    id: 3,
    link: 'https://app.snow.credit/login',
    image: WebApp,
    alt: 'webapp icon',
  },
];

function AppStoreLinks() {
  return (
    <Flex alignItems="center" gap={['1rem', '1.5rem']}>
      {appStores.map(({ id, link, image, alt }) => (
        <Link key={id} href={link}>
          <Image
            w={['120px', '140px', '168px']}
            h={['35px', '40px', '50px']}
            loading="lazy"
            src={image}
            alt={alt}
          />
        </Link>
      ))}
    </Flex>
  );
}

export default AppStoreLinks;
