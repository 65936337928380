import {
  BestExperience,
  FaqContainer,
  Hero,
  HowItWorks,
  LoanBoard,
  Offers,
} from 'features/home';
import Loans from 'features/home/Loans';
import React from 'react';

function Home() {
  return (
    <>
      <Hero />
      <LoanBoard />
      <Offers />
      <Loans />
      <BestExperience />
      <HowItWorks />
      <FaqContainer />
    </>
  );
}

export default Home;
