import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { OfferCardProps } from 'types';

function OfferCardFull({
  title,
  description,
  image,
  imageAlt,
  link,
  isPrimary = false,
  bgColor = 'white',
}: OfferCardProps) {
  return (
    <Flex
      h="full"
      maxH={['auto', 'auto', 'auto', 'auto', 'auto']}
      bg={bgColor}
      p="2.5rem"
      gap="2rem"
      borderRadius="15px"
    >
      <Box flex="1">
        <Heading color={isPrimary ? 'grey.700' : 'white'} fontSize="2rem">
          {title}
        </Heading>
        <Text mt="1rem" color={isPrimary ? 'grey.700' : 'white'}>
          {description}
        </Text>
        <Box mt="6.5rem">
          <Link href={link}>
            <Flex alignItems="center" gap="1.1rem">
              <Text
                fontSize="1.5rem"
                color={isPrimary ? 'red.500' : 'white'}
                fontWeight="bold"
              >
                Get Started
              </Text>
              <ArrowForwardIcon
                w="1.5rem"
                h="1.5rem"
                color={isPrimary ? 'red.500' : 'white'}
              />
            </Flex>
          </Link>
        </Box>
      </Box>
      <Image w="15rem" h="15rem" src={image} alt={imageAlt} />
    </Flex>
  );
}

export default OfferCardFull;
