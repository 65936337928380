import { FaqTypes } from 'types';

/* eslint-disable import/prefer-default-export */
export const faqs: FaqTypes = {
  general: [
    {
      id: 1,
      question: 'What is SnowCredit?',
      answer:
        'Snow Credit is a consumer lending app designed to make the lending process easy. The application grants you easy access to loans with just a few clicks.',
    },
    {
      id: 2,
      question: 'Is SnowCredit a bank?',
      answer:
        'No, Snow Credit is currently not a bank. We do not receive deposits from the general public, nor do we offer savings or current accounts services. We are however supported by a strong CBN licensed microfinance bank and finance house.',
    },
    {
      id: 3,
      question: 'How do I get started?',
      answer:
        'Our app is easily accessible on Google Play Store and iOS App Store for download and you can get started in just a few minutes. It is completely free.',
    },
    {
      id: 4,
      question: 'Is SnowCredit registered',
      answer:
        'Snow Credit is an expression of Snow Microfinance Bank and Snow Finance, both licensed by the Central Bank of Nigeria.',
    },
  ],
  loans: [
    {
      id: 1,
      question: 'How do SnowCredit loans work?',
      answer:
        'On the app, navigate to the Loans tab and click on Request for a Loan.  Once you complete our application form on your device, submit with the necessary clear pictures of your ID and within a few minutes, we will revert with a decision on your loan request. If approved, you will receive funds directly into your Snow Credit wallet within a few minutes of your loan approval.',
    },
    {
      id: 2,
      question: 'How do I apply for a loan on Snow Credit?',
      answer:
        'Applying for a loan is a very simple process on Snow Credit. Once you are logged into the App, navigate to the Loan section and fill in your personal and employment details for a loan request.',
    },
    {
      id: 3,
      question: 'What is the interest rate?',
      answer:
        'We operate a risk – based pricing model to determine repayment on loans.  This means that the interest on your loan request will typically range from 5% to 10% depending on several factors that have been programmed into our computer algorithm.',
    },
    {
      id: 4,
      question: 'What do I need to be eligible?',
      answer: `
      People who take loans should have a regular source of income wither from a salary, a steady business etc.  you should also be between 18 and 64 years old and you MUST have an active bank account.
      `,
    },
    {
      id: 5,
      question: 'How much can I borrow on SnowCredit?',
      answer:
        'For your first loan request, you can borrow as much as N10,000. The amount you can borrow grows as you repay and we get to know you better.',
    },
    {
      id: 6,
      question: 'What do I need to get a loan on Snow Credit?',
      answer:
        'While we do not collect collateral, we ask that you give us some personal details including employment (or business) information, residential address, a government issued ID card, a functional debit card and your BVN.  We DO NOT collect details of the contacts on your phone.',
    },
    {
      id: 7,
      question: 'Why is my Bank Verification Number (BVN) required?',
      answer:
        'Your BVN is used to verify that the individual applying for a Snow Credit loan is the same as the owner of the provided bank account. This is to prevent unauthorized use of your details by a 3rd party. Kindly note that providing your BVN does not provide access to your account. If in doubt, you may decide to confirm from your bank.',
    },
    {
      id: 8,
      question: 'How does Snow Credit make a lending decision?',
      answer:
        'A lending decision is made from several factors including your credit score which shows whether or not you have outstanding loans with other service providers as well as several other factors such as whether or not you can repay etc.',
    },
    {
      id: 9,
      question: 'Why was my loan request declined?',
      answer:
        'If you’ve had a loan request declined, then it means that your profile at the moment does not match the loan you’re requesting for.  Please check back with us again sometime soon or send an email to <a style="color: blue; text-decoration: underline" target="_blank" href="mailto:support@snow.credit">support@snow.credit</a>',
    },
    {
      id: 10,
      question: 'Will my loan size increase as I repay?',
      answer:
        'Yes, the more you repay the higher the loan amount you are eligible for.',
    },
    {
      id: 11,
      question:
        'I am yet to receive any notification on my loan status.  What could be wrong?',
      answer:
        'Notifications are usually sent via email immediately after your loan application is sent. If you have not received it, please contact us by email at <a style="color: blue; text-decoration: underline" target="_blank" href="mailto:support@snow.credit">support@snow.credit</a> or send a whatsapp message to +234 809 880 0002.',
    },
    {
      id: 12,
      question: 'How do I check my account balance and due dates?',
      answer:
        'These details can be found on the navigation pane within your app.  Once you log in, lookout for it on the left or right side of your app.',
    },
  ],
  repaymentAndTransactions: [
    {
      id: 1,
      question: 'How can I repay my loan?',
      answer:
        'You can repay your loan by doing a direct transfer to the account details that will be showing on your app or by accepting the auto-debit service.',
    },
    {
      id: 2,
      question: 'How does auto-debit work?',
      answer:
        'The auto-debit service makes loan repayment seamless. The service uses the debit card attached to your active loan to initiate a loan repayment for you. Auto-debit helps you stay true to your words and increase your credit score.',
    },
    {
      id: 3,
      question: 'What cards can I auto-debit?',
      answer:
        'Snow Credit supports MasterCard and Visa cards for auto-debit.  You just need to ensure that the account is sufficiently funded and the system takes care of the rest.',
    },
    {
      id: 4,
      question: 'Can I repay early?',
      answer:
        'Yes you can! You can pay as soon as you can afford to repay even if the loan is not due yet.',
    },
    {
      id: 5,
      question: 'What happens if I can’t make a payment on my due date?',
      answer:
        'We understand that financial circumstances can change, however, it is essential to note that we do not reschedule repayment dates and you should only take a SnowCredit loan if you are certain that you have the means to repay within the agreed period. There may be fees associated with late repayments. In addition, defaulting loans are reported to national Credit Bureaus, and this can severely affect your ability to access loans in future. We therefore advise you to make every effort to meet your obligations on time.',
    },
    {
      id: 6,
      question: 'Can my loan repayment date be rescheduled?',
      answer:
        'Repayment due dates are fixed when your loan is approved. We do not reschedule due dates, so kindly ensure appropriate planning to be able to meet repayments as at when due.',
    },
    {
      id: 7,
      question: 'What happens if I default on my loan?',
      answer: `
        We want to maintain you as a valued customer, so kindly ensure that we receive your repayments on time. 
        If you happen to be in a situation that makes it difficult to make your scheduled repayment, 
        kindly contact us immediately by sending an email to 
        <a style="color: blue; text-decoration: underline" target="_blank" href="mailto:support@snow.credit">support@snow.credit</a>.  
        There is a default penalty on the instalment amount for each day of default. If it exceeds 15 days, 
        we will seek to engage you to recover the loan.  We do our best to help our customers avoid unpleasant 
        situations and the resulting consequences, including but not limited to; <br />
        <br />
        <ul style="margin-left: 1rem">
          <li>Suspension of your access to our lending service</li>
          <li>Reporting to multiple credit bureaus thus restricting your access to loans from any finance organisation in the country</li>
          <li>Involvement with external collection agencies</li>
          <li>Possible legal action</li>
        </ul>
      `,
    },
    {
      id: 8,
      question: 'Can I pay down on my loan?',
      answer:
        'Yes, you can pay in instalments to reduce your total outstanding loan.',
    },
  ],
  security: [
    {
      id: 1,
      question: 'Why do I have to add my BVN?',
      answer:
        'Your BVN is strictly for identity verification to help us kick against identity theft and protect your funds and transactions from unauthorised persons.',
    },
    {
      id: 2,
      question: 'Are my card details safe?',
      answer:
        'Your security is our topmost priority. We warehouse your card details with a PCI-DSS compliant payment processor, Paystack for your security.',
    },
    {
      id: 3,
      question: 'What should I do when I need help?',
      answer:
        'The easiest way to reach us is to click on “Chat Support” under the Settings section of the app. You can also send an email to <a style="color: blue; text-decoration: underline" target="_blank" href="mailto:support@snow.credit">support@snow.credit</a>  or call +234 909 385 2301.',
    },
  ],
};
