import React from 'react';

import { useRoutes } from 'react-router-dom';
import { Faq, Home, Privacy } from 'pages';

function Routes() {
  return useRoutes([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/faq',
      element: <Faq />,
    },
    {
      path: '/privacy',
      element: <Privacy />,
    },
  ]);
}

export default Routes;
