/* eslint-disable react/jsx-props-no-spreading */
import { SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { LoanProps } from 'types/loan';
import LoanCard from './LoanCard';

const LoanData: LoanProps[] = [
  {
    title: 'Snow Credit Nano',
    description:
      'Nano loan for individuals earning a minimum of N50,000 per month.',
    amount: '₦10,000 - ₦20,000',
    tenor: '7-14 days',
    interest: '10% per month',
    link: 'https://app.snow.credit/login',
    color: 'red.500',
    bgColor: 'red.100',
  },
  {
    title: 'Snow Credit Midi',
    description:
      'Medium range loan for individuals who have taken a least two loans on Snow Credit.',
    amount: '₦20,000- ₦30,000',
    tenor: '7-14 days',
    interest: '10% per month',
    link: 'https://app.snow.credit/login',
    color: 'orange.500',
    bgColor: 'orange.100',
  },
  {
    title: 'Snow Credit Max',
    description: 'Maximum loan for frequent borrowers on Snow Credit.',
    amount: '₦50,000- ₦100,000',
    tenor: '1-3 months',
    interest: '5% per month',
    link: 'https://app.snow.credit/login',
    color: 'pink.500',
    bgColor: 'pink.100',
  },
];

function LoansWrapper() {
  return (
    <SimpleGrid columns={[1, 2, 2, 3]} spacing="1.5rem">
      {LoanData.map((loan) => (
        <LoanCard key={loan.title} {...loan} />
      ))}
    </SimpleGrid>
  );
}

export default LoansWrapper;
