import { Flex, Hide } from '@chakra-ui/react';
import { Container } from 'components';
import React from 'react';
import PrivacyContents from './PrivacyContents';
import PrivacyMobileDrops from './PrivacyMobileDrops';
import PrivacySidebar from './PrivacySidebar';

function PrivacyWrapper() {
  return (
    <Container>
      <Flex gap="3rem">
        <Hide below="lg">
          <PrivacySidebar />
        </Hide>
        <Hide above="lg">
          <PrivacyMobileDrops />
        </Hide>
        <PrivacyContents />
      </Flex>
    </Container>
  );
}

export default PrivacyWrapper;
