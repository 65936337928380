import { Box } from '@chakra-ui/react';
import React from 'react';
import Contact from './Contact';
import License from './License';
import Offers from './Offers';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';

function PrivacyContents() {
  return (
    <Box mt={['3rem', '7rem']}>
      <PrivacyPolicy />
      <Terms />
      <Offers />
      <License />
      <Contact />
    </Box>
  );
}

export default PrivacyContents;
