import { Box } from '@chakra-ui/react';
import { AppStores, Container } from 'components';
import { MainFaq } from 'features/faq';
import React from 'react';

function Faq() {
  return (
    <Box mt="10rem">
      <MainFaq />
      <Container>
        <AppStores />
      </Container>
    </Box>
  );
}

export default Faq;
