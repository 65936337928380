import { Box, Flex, Hide, Image } from '@chakra-ui/react';
import {
  Experience,
  ExperienceCard1,
  ExperienceCard2,
  ExperienceCard3,
  Phone,
} from 'assets/images';
import { Container } from 'components';
import SectionTitle from 'components/SectionTitle';
import React from 'react';

function BestExperience() {
  return (
    <Box mt={['6rem', '6rem', '10rem']}>
      <Container>
        <SectionTitle
          maxW="728px"
          title="You’ll always get the"
          subTitle="best experience"
          isMb
        />

        <Box
          bgColor="red.500"
          borderRadius="15px"
          mb="10rem"
          p={['1.5rem', '2rem 1.5rem', '2rem 1.5rem', '2rem 1.5rem', '2.5rem']}
        >
          <Flex gap="2rem">
            <Image
              src={Experience}
              w={['100%', '540px', '760px', '825px']}
              h="auto"
              loading="lazy"
              maxW={['auto', 'auto', 'auto', '600px', '760px']}
              alt="users experience"
            />

            <Hide below="lg">
              <Flex
                position="relative"
                alignItems="center"
                justifyContent="center"
                w="100%"
              >
                <Image src={Phone} w="280px" h="500px" />
                <Image
                  src={ExperienceCard1}
                  w="260px"
                  h="auto"
                  position="absolute"
                  top="4.5rem"
                  left="-45%"
                />
                <Image
                  src={ExperienceCard3}
                  w="260px"
                  h="auto"
                  position="absolute"
                  bottom="5rem"
                  left="-85%"
                />
                <Image
                  src={ExperienceCard2}
                  w="260px"
                  h="auto"
                  position="absolute"
                  bottom="0"
                  right="-12%"
                />
              </Flex>
            </Hide>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
}

export default BestExperience;
