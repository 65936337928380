/* eslint-disable react/jsx-props-no-spreading */
import { Grid, GridItem, Hide } from '@chakra-ui/react';
import React from 'react';
import { Collateral, Process, Safe } from 'assets/images';
import { OfferCardProps } from 'types/offer';
import OfferCard from './OfferCard';
import OfferCardFull from './OfferCardFull';

const offers: OfferCardProps[] = [
  {
    id: 1,
    title: 'Accessible loans with no collateral',
    description:
      'Request and get your loans approved in minutes with minimal paperwork and zero collateral.',
    image: Collateral,
    imageAlt: 'collateral',
    link: 'https://app.snow.credit/signup',
    isPrimary: true,
    bgColor: 'white',
  },
  {
    id: 2,
    title: 'Seamless application process',
    description:
      'With our seamless application process, you can apply for a loan in no time and get it disbursed to your account.',
    image: Process,
    imageAlt: 'process',
    link: 'https://app.snow.credit/signup',
    isPrimary: false,
    bgColor: 'orange.500',
  },
  {
    id: 3,
    title: 'Your data is safe with us',
    description:
      'With our secure online platform, all your personal information and confidential details are protected using the highest level of encryption.',
    image: Safe,
    imageAlt: 'safe',
    link: 'https://app.snow.credit/signup',
    isPrimary: false,
    bgColor: 'pink.500',
  },
];

function OffersWrapper() {
  return (
    <Grid
      templateRows={[
        'repeat(3, 1fr)',
        'repeat(3, 1fr)',
        'repeat(3, 1fr)',
        'repeat(5, 1fr)',
      ]}
      templateColumns={[
        '1fr',
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
      ]}
      gap="1.5rem"
    >
      {offers.map((offer) => (
        <GridItem
          key={offer.id}
          colSpan={offer.id === 3 ? [1, 2] : 1}
          rowSpan={offer.id === 3 ? [1, 2] : [1, 3]}
        >
          {offer.id === 3 ? (
            <>
              <Hide below="lg">
                <OfferCardFull {...offer} />
              </Hide>
              <Hide above="lg">
                <OfferCard h={['400px', '330px']} {...offer} />
              </Hide>
            </>
          ) : (
            <OfferCard {...offer} />
          )}
        </GridItem>
      ))}
    </Grid>
  );
}

export default OffersWrapper;
