/* eslint-disable react/require-default-props */
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { OfferCardProps } from 'types/offer';

function OfferCard({
  title,
  description,
  image,
  imageAlt,
  link,
  isPrimary = false,
  bgColor = 'white',
  h,
}: OfferCardProps) {
  return (
    <Box
      h={h || ['400px', '420px', '450px', '560px']}
      bg={bgColor}
      p={['2.5rem 1.5rem', '2.5rem 1.5rem', '2.5rem 1.5rem', '2.5rem']}
      borderRadius="15px"
    >
      <Flex h="full" flexDir="column" justifyContent="space-between">
        <Box>
          <Heading
            color={isPrimary ? 'grey.700' : 'white'}
            fontSize={['1.5rem', '1.5rem', '1.75rem', '2rem']}
          >
            {title}
          </Heading>
          <Text mt="1rem" color={isPrimary ? 'grey.700' : 'white'}>
            {description}
          </Text>
        </Box>
        <Flex alignItems="flex-end" justifyContent="space-between">
          <Link href={link}>
            <Flex alignItems="center" gap="1.1rem">
              <Text
                fontSize={['0.85rem', '1rem', '1rem', '1rem', '1.5rem']}
                color={isPrimary ? 'red.500' : 'white'}
                fontWeight="bold"
              >
                Get Started
              </Text>
              <ArrowForwardIcon
                w={['1rem', '1.5rem']}
                h={['1rem', '1.5rem']}
                color={isPrimary ? 'red.500' : 'white'}
              />
            </Flex>
          </Link>

          <Image
            w={['8rem', '8rem', '8rem', '15rem']}
            h={['8rem', '8rem', '8rem', '15rem']}
            src={image}
            alt={imageAlt}
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default OfferCard;
