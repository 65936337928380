/* eslint-disable react/require-default-props */
import { Heading } from '@chakra-ui/react';
import React from 'react';

interface SectionTitleProps {
  title: string;
  subTitle?: string;
  maxW?: string;
  isMb?: boolean;
  fontSize?: string[];
}

function SectionTitle({
  title,
  subTitle,
  maxW,
  isMb = true,
  fontSize,
}: SectionTitleProps) {
  return (
    <Heading
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDir={maxW ? 'column' : 'row'}
      gap={['0.6rem', '1rem']}
      fontSize={fontSize || ['1.5rem', '1.75rem', '2.35rem', '3rem', '4rem']}
      lineHeight={['1.75rem', '2rem', '2.75rem', '3.25rem', '4.25rem']}
      textAlign="center"
      color="grey.500"
      maxW={maxW}
      margin="0 auto"
      mb={isMb ? ['2rem', '4rem', '5rem'] : '0'}
    >
      {title}{' '}
      <Heading
        fontSize={fontSize || ['1.5rem', '1.75rem', '2.35rem', '3rem', '4rem']}
        lineHeight={['1.75rem', '2rem', '2.75rem', '3.25rem', '4.25rem']}
        color="red.500"
      >
        {subTitle}
      </Heading>
    </Heading>
  );
}

export default SectionTitle;
