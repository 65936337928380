import { Box } from '@chakra-ui/react';
import { Container } from 'components';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import LoansWrapper from './loans/LoansWrapper';

function Loans() {
  return (
    <Container>
      <Box id="loan" mt="10rem">
        <SectionTitle
          maxW="545px"
          title="There’s no shame"
          subTitle="in borrowing"
          fontSize={['1.5rem', '1.75rem', '2.35rem', '3rem', '3.5rem']}
        />

        {/* loans */}
        <LoansWrapper />
      </Box>
    </Container>
  );
}

export default Loans;
