import { FooterTypes } from 'types';

/* eslint-disable import/prefer-default-export */
export const footerContent: FooterTypes[] = [
  {
    title: 'Company',
    links: [
      {
        id: 1,
        name: 'Privacy Policy',
        url: '/privacy#privacy',
      },
      {
        id: 2,
        name: 'Terms of Use',
        url: '/privacy#terms',
      },
      {
        id: 3,
        name: 'License',
        url: '/privacy#license',
        last: true,
      },
    ],
  },
  {
    title: 'Contact  Us',
    links: [
      {
        id: 1,
        name: 'support@snow.credit',
      },
      {
        id: 2,
        name: '+234 809 880 0002',
        last: true,
      },
    ],
  },
  {
    title: 'Location',
    links: [
      {
        id: 1,
        name: '22 Association Avenue Ilupeju Lagos',
        last: true,
      },
    ],
  },
];
