/* eslint-disable import/prefer-default-export */
import { extendTheme } from '@chakra-ui/react';
import { Button } from './components/Button';

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'grey.100',
        color: 'gray.700',
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'hidden',
      },
      button: {
        _focus: {
          outline: 'none',
        },
      },
      'h1, h2, h3, h4, h5, h6': {
        fontWeight: '500',
      },
    },
  },
  fonts: {
    heading: 'Futura, system-ui, sans-serif',
    body: 'Inter, system-ui, sans-serif',
  },
  colors: {
    black: '#000000',
    red: {
      100: '#F2E1EA',
      500: '#ED3237',
    },
    white: '#FFFFFF',
    orange: {
      100: '#FDF3EB',
      500: '#ED8A32',
    },
    pink: {
      100: '#FDEBF4',
      500: '#D52D86',
    },
    blue: {
      500: '#2F80ED',
    },
    green: {
      500: '#27AE60',
    },
    grey: {
      100: '#F3F3F3',
      300: '#7D7D7D',
      500: '#4F4F4F',
      700: '#1B1C1E',
      900: '#070707',
    },
  },
  components: {
    Button,
  },
});
