import { Box } from '@chakra-ui/react';
import { Container } from 'components';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import OffersWrapper from './offers/OffersWrapper';

function Offers() {
  return (
    <Container>
      <Box mt={['5rem', '10rem']}>
        <SectionTitle title="What we" subTitle="offer" />

        {/* offers */}
        <OffersWrapper />
      </Box>
    </Container>
  );
}

export default Offers;
