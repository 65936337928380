import { AppStores } from 'components';
import PrivacyWrapper from 'features/privacy/PrivacyWrapper';
import React from 'react';

function Privacy() {
  return (
    <>
      <PrivacyWrapper />
      <AppStores />
    </>
  );
}

export default Privacy;
