import {
  Box,
  Button,
  Flex,
  Hide,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import React from 'react';

import { Logo } from 'assets/images';
import Container from './Container';

function Header() {
  return (
    <Box position="sticky" top="0" zIndex="1" bg="red.100" w="full">
      <Container>
        <Flex h="6rem" alignItems="center" justifyContent="space-between">
          <Flex h="full" alignItems="center" gap="3.75rem">
            <Link as={ReachLink} to="/">
              <Image w="100px" h="auto" src={Logo} alt="snowcredit logo" />
            </Link>
            <Hide below="lg">
              <Flex h="full" pt="1rem" gap="2.5rem" alignItems="center">
                <Link fontSize="1rem" fontWeight="medium" href="/#loan">
                  Loans
                </Link>
                <Link fontSize="1rem" fontWeight="medium" href="/#footer">
                  Contact
                </Link>
                <Link fontSize="1rem" fontWeight="medium" href="/#faq">
                  FAQs
                </Link>
              </Flex>
            </Hide>
          </Flex>

          <Hide below="lg">
            <Flex h="full" alignItems="center" gap="1rem">
              <Button
                as="a"
                href="https://app.snow.credit/login"
                target="_blank"
                variant="secondary"
              >
                Log In
              </Button>
              <Button
                as="a"
                href="https://app.snow.credit/signup"
                target="_blank"
                variant="primary"
              >
                Get Started
              </Button>
            </Flex>
          </Hide>

          <Hide above="lg">
            <Menu>
              <MenuButton
                aria-label="Dropdown menu"
                aria-labelledby="dropdown menu"
                bg="none"
                as={Button}
              >
                <HamburgerIcon color="black" fontSize="1.25rem" width="auto" />
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Link fontSize="1rem" fontWeight="medium" href="/#loan">
                    Loans
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link fontSize="1rem" fontWeight="medium" href="/#footer">
                    Contact
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link fontSize="1rem" fontWeight="medium" href="/#faq">
                    FAQs
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Button
                    w="full"
                    as="a"
                    href="https://app.snow.credit/login"
                    target="_blank"
                    variant="secondary"
                    aria-label="Log In"
                    aria-labelledby="Log In"
                  >
                    Log In
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    w="full"
                    as="a"
                    href="https://app.snow.credit/signup"
                    target="_blank"
                    variant="primary"
                    aria-label="Get Started"
                    aria-labelledby="Get Started"
                  >
                    Get Started
                  </Button>
                </MenuItem>
              </MenuList>
            </Menu>
          </Hide>
        </Flex>
      </Container>
    </Box>
  );
}

export default Header;
