/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { Container } from 'components';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import { Faq } from 'types';

interface FaqWrapperProps {
  faqs: Faq[];
  category?: 'repaymentAndTransactions' | 'loans' | 'security';
  setCategory?: any;
  link?: boolean;
}

function FaqWrapper({
  faqs,
  link = false,
  category,
  setCategory,
}: FaqWrapperProps) {
  return (
    <Container>
      <SectionTitle title="How can we" subTitle="help?" />

      {category && (
        <Flex
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
          gap={['1rem', '2rem']}
        >
          <Button
            bgColor={category === 'loans' ? 'red.100' : 'grey.100'}
            color={category === 'loans' ? 'red.500' : 'grey.900'}
            onClick={() => setCategory('loans')}
          >
            Getting a Loan
          </Button>
          <Button
            bgColor={
              category === 'repaymentAndTransactions' ? 'red.100' : 'grey.100'
            }
            color={
              category === 'repaymentAndTransactions' ? 'red.500' : 'grey.900'
            }
            onClick={() => setCategory('repaymentAndTransactions')}
          >
            Repayment and transations
          </Button>
          <Button
            bgColor={category === 'security' ? 'red.100' : 'grey.100'}
            color={category === 'security' ? 'red.500' : 'grey.900'}
            onClick={() => setCategory('security')}
          >
            Security
          </Button>
        </Flex>
      )}

      <Box
        mt="5rem"
        w="full"
        bg="red.500"
        p={['2rem 1rem', '2rem 1.5rem', '3rem 2rem', '6.25rem']}
        borderRadius="30px"
      >
        <Accordion defaultIndex={[0]}>
          {faqs.map((faq, index) => (
            <AccordionItem
              key={faq.id}
              bgColor="white"
              borderRadius="15px"
              px={['0.5rem', '0.75rem', '2.5rem']}
              py="1rem"
              mb={faqs.length !== index ? '1.5rem' : '0'}
            >
              <h2>
                <AccordionButton>
                  <Heading
                    fontSize={['0.875rem', '1rem', '1.5rem']}
                    fontWeight={['600', '500']}
                    flex="1"
                    textAlign="left"
                    color="grey.500"
                  >
                    {faq.question}
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                fontSize={['0.9rem', '1.25rem']}
                color="grey.500"
                pb={4}
              >
                <Text dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        {link && (
          <Link href="/faq">
            <Flex mt="0.75rem" alignItems="center" gap="1.1rem">
              <Text
                fontSize={['1rem', '1.5rem']}
                color="white"
                fontWeight="bold"
              >
                See all questions
              </Text>
              <ArrowForwardIcon
                w={['1rem', '1.5rem']}
                h={['1rem', '1.5rem']}
                color="white"
              />
            </Flex>
          </Link>
        )}
      </Box>
    </Container>
  );
}

export default FaqWrapper;
