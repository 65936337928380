import { Box, Flex, Image, Link, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import { Instagram, Facebook, Twitter, Logo } from 'assets/images';
import { footerContent } from 'constants/footer';
import Container from './Container';

function Footer() {
  return (
    <Box
      id="footer"
      bgColor="red.100"
      borderTopWidth="1px"
      borderColor="red.500"
      mt="15rem"
    >
      <Container>
        <Box py="2.5rem">
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="1.5rem"
          >
            <Text fontSize=".75rem" color="grey.500">
              Find us on Socials
            </Text>

            <Flex justifyContent="center" alignItems="center" gap="2.5rem">
              <Link target="_blank" href="/">
                <Image src={Twitter} alt="Twitter" />
              </Link>
              <Link
                target="_blank"
                href="https://www.instagram.com/snowcredit/"
              >
                <Image src={Instagram} alt="Instagram" />
              </Link>
              <Link target="_blank" href="https://www.facebook.com/snowcredit">
                <Image src={Facebook} alt="Facebook" />
              </Link>
            </Flex>
          </Flex>

          <SimpleGrid columns={[1, 4]} spacing="2rem" mt="2.5rem">
            <Link margin={['0 auto', '0']} href="/">
              <Image w="125px" h="auto" src={Logo} alt="snowcredit logo" />
            </Link>
            {footerContent.map(({ title, links }) => (
              <Box key={title}>
                <Text
                  fontSize="0.875rem"
                  fontWeight="600"
                  color="red.500"
                  mb="1.5rem"
                  textAlign={['center', 'left']}
                >
                  {title}
                </Text>

                {links.map(({ name, url, last }) => (
                  <Box key={name} mb={!last ? '1rem' : '0'}>
                    {url ? (
                      <Link
                        textAlign={['center', 'left']}
                        w="full"
                        display="block"
                        href={url}
                        key={name}
                      >
                        {name}
                      </Link>
                    ) : (
                      <Text textAlign={['center', 'left']} key={name}>
                        {name}
                      </Text>
                    )}
                  </Box>
                ))}
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
