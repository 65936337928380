import { Box, Flex, Image, SimpleGrid } from '@chakra-ui/react';
import { Phone2 } from 'assets/images';
import { AppStoreLinks, Container } from 'components';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import StepCard from './how/StepCard';

function HowItWorks() {
  return (
    <Box>
      <SectionTitle title="How it" subTitle="works" />
      <Box bgColor="red.100" mb="10rem" py="2.5rem">
        <Container>
          <Flex alignItems="center" flexDir={['column', 'row']} gap="2rem">
            <Image
              loading="lazy"
              src={Phone2}
              alt="how it works"
              w="250px"
              h="400px"
            />

            <Box w="full">
              <SimpleGrid columns={[1, 2]} spacing="2rem">
                <StepCard
                  title="Step 1"
                  desc="Download the SnowCredit App either on Play Store or App Store or visit our Web App."
                />
                <StepCard
                  title="Step 2"
                  desc="Sign up fast and easily on the SnowCredit Mobile or Web App."
                />
                <StepCard
                  title="Step 3"
                  desc="Complete your loan application and submit a valid identification and your bank details."
                />
                <StepCard
                  title="Step 4"
                  desc="If eligible, your loan amount will be credited into your wallet within 24 hours."
                />
              </SimpleGrid>
              <Flex justifyContent="center" py="2rem">
                <AppStoreLinks />
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
}

export default HowItWorks;
