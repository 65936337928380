import { Badge, Box, Text } from '@chakra-ui/react';
import React from 'react';

function StepCard({ title, desc }: { title: string; desc: string }) {
  return (
    <Box>
      <Badge
        bgColor="red.500"
        color="white"
        fontSize="1rem"
        borderRadius="24px"
        p="0.5rem 1rem"
      >
        {title}
      </Badge>

      <Text fontSize="1.1rem" color="grey.500" mt="1rem">
        {desc}
      </Text>
    </Box>
  );
}

export default StepCard;
