/* eslint-disable react/jsx-props-no-spreading */
import {
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { formatCurrency } from 'utils/formatCurrency';
import { formatMonth } from 'utils/formatMonth';

function LoanSlider({
  value,
  onChange,
  min,
  max,
  isMoney = false,
  transform,
}: any) {
  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  };
  return (
    <Slider
      aria-label="slider-ex-6"
      colorScheme="grey"
      value={value}
      onChange={(val) => onChange(val)}
      min={min}
      max={max}
    >
      <SliderMark value={min} {...labelStyles} ml="0">
        {isMoney
          ? formatCurrency(min, { showCurrency: true })
          : formatMonth(min)}
      </SliderMark>
      <Text position="absolute" right="0" {...labelStyles}>
        {isMoney
          ? formatCurrency(max, { showCurrency: true })
          : formatMonth(max)}
      </Text>
      <SliderMark
        value={value}
        textAlign="center"
        bg="grey.500"
        color="white"
        mt="-10"
        ml="-5"
        w="auto"
        px="0.5rem"
        py="-0.25rem"
        borderRadius="10px"
        transform={transform}
        whiteSpace="nowrap"
      >
        {isMoney
          ? formatCurrency(value, { showCurrency: true })
          : formatMonth(value)}
      </SliderMark>
      <SliderTrack bgColor="red.100" h="8px" borderRadius="15px">
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb w="1.25rem" h="0.75rem" bgColor="grey.500" />
    </Slider>
  );
}

export default LoanSlider;
