import {
  Box,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';

function Offers() {
  return (
    <Box id="offers">
      <Text
        fontSize="1.5rem"
        fontWeight="700"
        color="black"
        mt="2.5rem"
        mb="1.5rem"
      >
        Offer Letter
      </Text>

      <Text fontSize="1.15rem" fontWeight="600" color="black" mb="1rem">
        Advance and Repayment
      </Text>
      <OrderedList>
        <ListItem mb="0.75rem">
          Page agrees to advance the Loan and the Obligor agrees to take the
          loan subject to the terms and conditions as set out in the loan
          application flow and also herein in this offer letter terms and
          conditions.
        </ListItem>
        <ListItem mb="0.75rem">
          The Obligor agrees to repay the Loan (principal plus accrued interest)
          in accordance with the terms and conditions document.
        </ListItem>
      </OrderedList>

      <Text
        fontSize="1.15rem"
        fontWeight="600"
        color="black"
        mt="1.5rem"
        mb="1rem"
      >
        Interest
      </Text>
      <OrderedList>
        <ListItem mb="0.75rem">
          The repayment installments as calculated in the dedicated repayment
          schedule are done on the basis of payment at regular agreed intervals.
          If the due date for a repayment installment falls on a non-business
          day, then the repayment installment may be paid on the next succeeding
          business day. Additional interest may accrue in such cases.
        </ListItem>
        <ListItem mb="0.75rem">
          Snow Credit will calculate interest on the daily balance outstanding.
        </ListItem>
        <ListItem mb="0.75rem">
          Where the Obligor fails to pay Snow Credit a repayment installment by
          the due date, Snow Credit will charge a default interest rate of 1.0%
          per week on the unpaid sum.
        </ListItem>
        <ListItem mb="0.75rem">
          Snow Credit will apply a default interest rate from the date on which
          the Obligor was due to pay the sum.
        </ListItem>
        <ListItem mb="0.75rem">
          <b>The default interest rate</b>

          <UnorderedList>
            <ListItem mb="0.75rem">
              Is charged by Snow Credit in addition to the normal interest rate
              which it charges the Obligor at the same time and in the same
              manner as that normal interest.
            </ListItem>
            <ListItem mb="0.75rem">
              Accrues before and after any court judgment, Snow Credit obtains
              against the Obligor.
            </ListItem>
            <ListItem mb="0.75rem">
              Is liquidated damages to compensate Snow Credit for the additional
              risk and other administrative costs and expenses associated with
              the Obligor’s failure to pay the outstanding sum.
            </ListItem>
          </UnorderedList>
        </ListItem>
      </OrderedList>

      <Text
        fontSize="1.15rem"
        fontWeight="600"
        color="black"
        mt="0.5rem"
        mb="1rem"
      >
        Representations and Warranties
      </Text>
      <OrderedList>
        <ListItem mb="0.75rem">
          He/she has the right to accept this facility and has taken all
          necessary actions to authorise the same upon the terms and conditions
          herein.
        </ListItem>
        <ListItem mb="0.75rem">
          He/she is not in default under any obligation in respect of any
          borrowed Money that the acceptance of this facility will be or result
          in breach of or default under any provision of any other agreement to
          which the Obligor is a party.
        </ListItem>
        <ListItem mb="0.75rem">
          The information given to Snow Credit electronically via the Snow
          Credit app or email correspondence in the formal loan application
          process is true and accurate
        </ListItem>
        <ListItem mb="0.75rem">
          He/she will use the loan for the purpose(s) they told Snow Credit of
          in their application for the loan.
        </ListItem>
      </OrderedList>

      <Text
        fontSize="1.15rem"
        fontWeight="600"
        color="black"
        mt="0.5rem"
        mb="1rem"
      >
        Events of Default
      </Text>
      <Text mb="0.75rem">
        The occurrence of any of the following events shall cause all
        outstanding amounts under this facility to become immediately due and
        payable:
      </Text>
      <OrderedList>
        <ListItem mb="0.75rem">
          The Obligor fails to make a repayment or payment of principal,
          interest or other amounts in respect of the loan on the date it was
          due to be paid; or
        </ListItem>
        <ListItem mb="0.75rem">
          The Obligor breaches any of the terms and conditions of the Loan
          including any representation or confirmation given by the Obligor in
          this loan agreement.
        </ListItem>
        <ListItem mb="0.75rem">
          Where a bankruptcy petition is filed against the Obligor.
        </ListItem>
        <ListItem mb="0.75rem">
          Where the Obligor is unable to pay any other party within the meaning
          of Section 1 of the Bankruptcy Act (Cap 30) Laws of the Federation of
          Nigeria.
        </ListItem>
        <ListItem mb="0.75rem">
          Where a situation arises which in the opinion of Snow Credit makes it
          inappropriate for Snow Credit to continue to extend the facility to
          the Obligor.
        </ListItem>
        <ListItem mb="0.75rem">
          Where the Obligor defaults in the performance or observance of any
          other term, condition or covenant herein and such breach or default
          shall continue unremedied after seven days’ notice shall have been
          given to the Obligor.
        </ListItem>
      </OrderedList>

      <Text
        fontSize="1.15rem"
        fontWeight="600"
        color="black"
        mt="0.5rem"
        mb="1rem"
      >
        Costs and Expenses
      </Text>
      <OrderedList>
        <ListItem mb="0.75rem">
          The Obligor hereby agrees to pay all administrative costs, charges and
          expenses (including legal fees) reasonably incurred by Snow Credit in
          connection with the processing of this loan request.
        </ListItem>
        <ListItem mb="0.75rem">
          The Obligor hereby agrees to pay all costs and expenses (including
          legal fees) incurred by Snow Credit in connection with any proceedings
          instituted by or against Snow Credit as a consequence of entering into
          this loan agreement.
        </ListItem>
      </OrderedList>

      <Text
        fontSize="1.15rem"
        fontWeight="600"
        color="black"
        mt="0.5rem"
        mb="1rem"
      >
        Assignment and Disclosure of Information
      </Text>
      <OrderedList>
        <ListItem mb="0.75rem">
          The Obligor consents irrevocably to any future transfer and
          assignment, however arising of the loan, whether as part of a loan
          transfer scheme or otherwise.
        </ListItem>
        <ListItem mb="0.75rem">
          The Obligor authorises Snow Credit to disclose any information or
          documentation relating to the loan to third parties including credit
          reference agencies, collection agencies, as well as the Obligor’s
          employers (where the Obligor is in salaried employment) in the event
          that the loan has ceased to be serviced by the Obligor.
        </ListItem>
      </OrderedList>

      <Text
        fontSize="1.15rem"
        fontWeight="600"
        color="black"
        mt="0.5rem"
        mb="1rem"
      >
        Variation of Conditions
      </Text>
      <OrderedList>
        <ListItem mb="0.75rem">
          Snow Credit reserves the right at all times to vary the terms and
          conditions of the loan agreement. Any such variation will become
          effective upon notice to the Obligor by any means Snow Credit
          considers reasonable in the circumstance.
        </ListItem>
      </OrderedList>

      <Text
        fontSize="1.15rem"
        fontWeight="600"
        color="black"
        mt="0.5rem"
        mb="1rem"
      >
        Set-Off
      </Text>
      <OrderedList>
        <ListItem mb="0.75rem">
          Snow Credit may at any time with or without notice (a) combine or
          consolidate some or all of the Obligor’s accounts with it without any
          liability to Snow Credit and (b) set off and transfer any sum standing
          to the credit of any such account in full or partial payment of any
          amount the Obligor owes to the Company.
        </ListItem>
        <ListItem mb="0.75rem">
          This clause is in addition to and does not amend or qualify any other
          present or future right of Snow Credit to combine or set off any of
          the Obligor’s accounts with it.
        </ListItem>
      </OrderedList>

      <Text
        fontSize="1.15rem"
        fontWeight="600"
        color="black"
        mt="0.5rem"
        mb="1rem"
      >
        Governing Law
      </Text>
      <OrderedList>
        <ListItem mb="0.75rem">
          This loan agreement is governed by Nigerian law and the courts of the
          Federal Republic of Nigeria have jurisdiction in any matter arising
          from it.
        </ListItem>
        <ListItem mb="0.75rem">
          This offer is subject to a satisfactory credit report and further
          verification checks.
        </ListItem>
      </OrderedList>

      <Text
        fontSize="1.15rem"
        fontWeight="600"
        color="black"
        mt="0.5rem"
        mb="1rem"
      >
        DISCLAIMER:
      </Text>
      <Text>
        As required by law, Snow Credit is mandated to disclose the status of
        defaulting loans to relevant local or international Credit Bureaus,
        Credit Monitoring Agencies and Regulatory Bodies.
      </Text>
    </Box>
  );
}

export default Offers;
