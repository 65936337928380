import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import React from 'react';

function PrivacyMobileDrops() {
  return (
    <Menu>
      <MenuButton
        as={Flex}
        pos="fixed"
        top="8.5rem"
        right="0.875rem"
        bg="red.100"
        borderRadius="0.75rem"
        p="0.75rem 1rem"
      >
        <HamburgerIcon width="auto" />
      </MenuButton>

      <MenuList>
        <MenuItem>
          <Link _hover={{ textDecoration: 'none' }} href="/privacy#privacy">
            Privacy Policy
          </Link>
        </MenuItem>
        <MenuItem>
          <Link _hover={{ textDecoration: 'none' }} href="/privacy#terms">
            Terms of Use
          </Link>
        </MenuItem>
        <MenuItem>
          <Link _hover={{ textDecoration: 'none' }} href="/privacy#offers">
            Offer Letter
          </Link>
        </MenuItem>
        <MenuItem>
          <Link _hover={{ textDecoration: 'none' }} href="/privacy#license">
            End User License Agreement
          </Link>
        </MenuItem>
        <MenuItem>
          <Link _hover={{ textDecoration: 'none' }} href="/privacy#contact">
            Contact Us
          </Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default PrivacyMobileDrops;
